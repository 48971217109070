//user
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const REGISTER = "REGISTER";
export const SAVE_MATCHES = "SAVE_MATCHES";
export const SAVE_COMMUNITIES = "SAVE_COMMUNITIES";
export const SAVE_STREAMERS = "SAVE_STREAMERS";
export const SAVE_COLLECIBLES = "SAVE_COLLECIBLES";
export const SAVE_RECENT_MATCHES = "SAVE_RECENT_MATCHES";
export const SAVE_STREAMER_LEADER = "SAVE_STREAMER_LEADER";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_EPICNAME = "UPDATE_EPICNAME";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SAVE_PRIZES = "SAVE_PRIZES";
export const SET_VERIFY_FROM = 'SET_VERIFY_FROM';
export const SAVE_PARTNER_PRODUCTS = "SAVE_PARTNER_PRODUCTS";
export const SAVE_PARTNERS = "SAVE_PARTNERS";
export const SET_USER_ID = "SET_USER_ID"

export const SAVE_EVENT = "SAVE_EVENT";