import React, { useEffect, useState, useMemo, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import * as API from "../util/api";
import { Input, message, } from 'antd';
import { debounce } from "lodash";

const GameBot = ({game}) => {
  const [messages, setMessages] = useState([
      {
        role:'system',
        content:`Hello! I'm your Game Assistant, and I'm here to assist you with any questions or information you need about ${game?.name}. Feel free to ask, and let's explore ${game?.name} together!`
      }
  ]);

  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const mesRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
}, [messages]);

const scrollToBottom = () => {
  if (mesRef.current) {
    mesRef.current.scrollTop = mesRef.current.scrollHeight;
  }
};

const debounceSendMessage = debounce(() => {
  sendMessage()
}, 500);


const sendMessage = async () => {
  const newUserMessage = {
    role:'user',
    content:input
  }
  const newMessages = [...messages, newUserMessage];
  setMessages(newMessages);
  try {
    const requestBody = {
      game:game.name,
      messages:newMessages
    }
    const endpoint = API.PROMOTE_GAME_ASSISTANT_CHAT;
    const res = await axios.post(endpoint, requestBody);
    if(res.status === 200){
      const newAssistantMessage = res.data.message;
      setInput("");
      setMessages([...newMessages, newAssistantMessage]);
    }else {
      message.error("Failed to send message, please try again")
    }
  }catch(err){
    console.log(err);
    message.error("Failed to send message, please try again")
  }finally{
    setLoading(false);
  }
}

  return <Container>
    <MessageBox ref={mesRef}>
  {messages.map((item) => {
    const {role, content} = item;
    if(role === 'system'){
      return <AssistantMessage>
        {content}
      </AssistantMessage>
    }else if (role === 'assistant'){
      return <AssistantMessage>
        {content}
      </AssistantMessage>
    }else {
      return <UserMessage>
        {content}
      </UserMessage>
    }
  })}
    </MessageBox>
  {
      loading ? <PlaceholderContainer>
         We are working on it... Please hold on!
        </PlaceholderContainer> : <div style={{display:'flex'}}>
      <StyledInput placeholder='Type In Your Message Here' onChange={(e) => {
        setInput(e.target.value)
      }
    }
    onPressEnter={() => {
      if(!loading){
        setLoading(true)
        debounceSendMessage()
      }
    }}
      value={input}
      suffix={
        <img src="/assets/chat_send_icon.svg" alt="send" style={{width:24, height:24}}
        onClick={() => {
          if(messages.length > 20){
            message.error("You are over the limit of 20 messages")
          }else if(!loading && input !== ""){
            setLoading(true)
            debounceSendMessage()
          }
        }}
        />
      }
      showCount 
      maxLength={96} 
      />
    </div>
    }
    </Container>
}

const Container = styled.div`
height:452px;
max-width:616px;
border-radius: 10px;
background: #000;
padding:12px;
display:flex;
flex-direction:column;
.ant-input-affix-wrapper > .ant-input {
  background: #1C1D20;

color: #FFF;
}

.ant-input-show-count-has-suffix {
  color:white;
}

@media (max-width: 768px) {
  width: 100%; // Adjust for smaller screens;
  margin-bottom:24px;
}

`;

const MessageBox = styled.div`
overflow-y: auto;
display:flex;
flex-direction:column;
flex-grow: 1;
margin-bottom:12px;
`;

const AssistantMessage = styled.div`
max-width:80%;
padding:6px;
border-radius:10px;
background: #E9E9EB;
align-self: flex-start; 
margin-bottom:8px;
color: #000;

font-family: SF Pro Display;
font-size: 14px;
`;

const UserMessage = styled.div`
max-width:80%
padding:4px;
border-radius:10px;
background: #3CABFC;
color: white;
align-self: flex-end; 
margin-bottom:8px;
padding:6px;

font-family: SF Pro Display;
font-size: 14px;
`;

const PlaceholderContainer = styled.div`
height: 38px;
width:100%;
border-radius: 5px;
background: #1C1D20;
border:none;
margin:0px auto;
color:white;
font-family: SF Pro Display;
text-align:center;
line-height:38px;
`;

const StyledInput = styled(Input)`
  height: 38px;
  width:100%;
  border-radius: 5px;
background: #1C1D20;
border:none;

color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
margin:0px auto;
`;

export default GameBot
