import * as ActionTypes from "./type";

export const login = (profile, id) => (dispatch) => {
  if (profile && id) {
    dispatch({
      type: ActionTypes.LOG_IN,
      profile,
      id,
    });
  }
};

export const register = (values, history) => (dispatch) => {
  dispatch({
    type: ActionTypes.REGISTER,
    payload: values,
  });
  history.push("/login");
};

export const updateToken = (currentToken) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_TOKEN,
    payload: currentToken,
  });
};

export const updateEpicName = (epicName) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_EPICNAME,
    payload: epicName,
  });
};

export const updateProfile = (profile) => (dispatch) => {
  console.log('update profile')
  dispatch({
    type: ActionTypes.UPDATE_PROFILE,
    payload: profile,
  });
};

export const updateEvents = (events) => (dispatch) => {
  console.log('update event')
  dispatch({
    type: ActionTypes.SAVE_EVENT,
    payload: events,
  });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOG_OUT,
  });
};

export const setVerifyFrom = (verify_from) => (dispatch) => {
  dispatch({
    type:ActionTypes.SET_VERIFY_FROM,
    payload: verify_from
  })
}