/* const domain = "http://localhost:8000"; */
// const domain = "https://arcadequest-l3pj.onrender.com";
// new server since March 27, 2024
const domain = "https://server-bvz8.onrender.com";
/* const domain = "https://testserver-fnby.onrender.com"; */
/* const statsDomain = "http://localhost:8000"; */
/* const serviceBotDomain = "http://localhost:8000"; */
const serviceBotDomain = "https://arcadequestservicebot.onrender.com";
/* const rescheduleDomain = "http://localhost:8000"; */

//user
export const SIGNUP = `${domain}/user/signup`;

export const SAVE_PASSWORD = `${domain}/user/save_password`;
export const FETCH_USER_EPICNAME = `${domain}/user/get_epicName`;
export const UNLINK_EPICACCOUNT = `${domain}/user/unlink_epicId`;
export const UPDATE_FIRSTTIME = `${domain}/user/update_first_time`;
export const CHANGE_NAME = `${domain}/user/change_name`;
export const CREATE_USER_DOC = `${domain}/user/create_doc`;
export const SAVE_USER_ROLE = `${domain}/user/select_role`;


//referral
export const REFRESH_REFERRAL = `${domain}/referral/refresh`;
export const REFERRAL_BOUNS = `${domain}/referral/giveBouns`;

export const CREATE_EXCHANGE_ORDER= `${domain}/prize/create_exchange_order`;
export const CONFIRM_EXCHANGE_ORDER = `${domain}/prize/confirm_exchange_order`;

//chat
export const SEND_CHAT_MESSAGE = `${domain}/chat/send`;

// stripe
export const STRIPE_CHECKOUT = `${domain}/stripe/create_checkout_session`;
export const STRIPE_CHECKOUT_TICKETS = `${domain}/stripe/buy_tickets`;
export const STRIPE_GET_STATUS = `${domain}/stripe/get_session_status`;
export const STRIPE_CHECKOUT_MATCH = `${domain}/stripe/create_match_checkout`;
export const STRIPE_SUB = `${domain}/stripe/sub`;
export const STRIPE_SUB_TRIAL = `${domain}/stripe/sub_start_trial`;
export const STRIPE_UNSUB = `${domain}/stripe/unSub`;
export const STRIPE_REACTIVE = `${domain}/stripe/reactive`;
export const STRIPE_RENEW = `${domain}/stripe/renew`;
export const STRIPE_MULTIPLIER_SUB = `${domain}/stripe/multiplierSub`;
export const STRIPE_UNSUB_MULTIPLIER = `${domain}/stripe/unSubMultiplier`;
export const STRIPE_REACTIVE_MULTIPLIER = `${domain}/stripe/reactiveMultiplier`;



// profile
export const FETCH_USER_PROFILE = `${domain}/profile`;
export const FETCH_USER_PROFILE_BASIC = `${domain}/profile/basic`;
export const FETCH_USER_PROFILE_EPIC = `${domain}/profile/epic`;
export const FOLLOW_USER = `${domain}/profile/follow`;
export const UNFOLLOW_USER = `${domain}/profile/unfollow`;


//sub
export const STORE_SUB = `${domain}/subs/webSubStore`;
export const STROE_SUB_TRIAL =  `${domain}/subs/webSubTrialStore`;
export const STORE_MULTIPLIER_SUB = `${domain}/subs/webMultiplierStore`;

//epicService

export const LINK_ACCOUNT = `${domain}/epicService/link`;
export const CHECK_ACCOUNT_PRIVATE = `${domain}/epicService/checkAccount`;



// notification
export const FETCH_NOTIFICATION = `${domain}/notifications`;

export const SUBMIT_GAME_REVIEW = `${domain}/partner/submit_game_review`;
export const GET_GAME_SHORTLIST = `${domain}/partner/get_game_shortlist`;
export const GET_GAME_ALLLIST = `${domain}/partner/get_game_allList`;

//quest
export const GET_QUESTS_IDS = `${domain}/quests/ids`;
export const GET_DAILY_QUESTS_IDS = `${domain}/quests/daily_ids`;
export const JOIN_QUEST = `${domain}/quests/join`;
export const JOIN_DAILYQUEST = `${domain}/quests/join_daily`;
export const LEAVE_QUEST= `${domain}/quests/leave`;
export const LEAVE_QUEST_DAILY = `${domain}/quests/leave_daily`;
export const GET_QUESTS_LEADER =`${domain}/quests/leaderboard`;
export const GET_RECOMMEND_QUESTS = `${domain}/quests/recommends`;
export const CLAIM_FREE_TICKERS = `${domain}/quests/claimTickets`;

// creatorQuest 
export const CREATE_CREATORQUEST = `${domain}/creatorQuest/create`;
export const CREATE_CREATORQUEST_FIXEDPOOL = `${domain}/creatorQuest/create_fixPool`;
export const CREATE_CREATORQUEST_WITHPOOL = `${domain}/creatorQuest/create_withPool`;
export const JOIN_CREATORQUEST = `${domain}/creatorQuest/join`;
export const LEAVE_CREATORQUEST = `${domain}/creatorQuest/leave`;
export const SEND_CREATORQUEST_CHAT = `${domain}/chat/createQuest_send`;
export const START_CREATORQUEST = `${domain}/creatorQuest/start`;
export const CANCEL_CREATORQUEST = `${domain}/creatorQuest/cancel`;
export const JOIN_CREATORQUEST_AS_SUB = `${domain}/creatorQuest/join_as_sub`;
export const LEAVE_CREATORQUEST_AS_SUB = `${domain}/creatorQuest/leave_as_sub`;
export const LEAVE_CREATORQUEST_WITH_TICKET = `${domain}/creatorQuest/leave_with_tickets`;
export const JOIN_CREATORQUEST_WITH_TICKETS = `${domain}/creatorQuest/join_with_tickets`;
export const JOIN_CREATORQUEST_WITH_AD = `${domain}/creatorQuest/join_with_ad`;
export const LEAVE_CREATORQUEST_WITH_AD = `${domain}/creatorQuest/leave_with_ad`;
export const GET_PROMO_BOUNS = `${domain}/creatorQuest/getPromoBouns`;

export const RESCHEDULE_QUEST = `${domain}/creatorQuest/reschedule`;

//  pro league
export const CREATE_PROLEAGUE = `${domain}/proLeague/create`;
export const CREATE_PROLEAGUE_WITHPOOL = `${domain}/proLeague/create_withPool`;
export const JOIN_PROLEAGUE =  `${domain}/proLeague/join`;
export const LEAVE_PROLEAGUE = `${domain}/proLeague/leave`;
export const RESCHEDULE_PROLEAGUE = `${domain}/proLeague/reschedule`;
export const CANCEL_PROLEAGUE = `${domain}/proLeague/cancel`;

//tickets
export const BUY_TICKETS_WITH_COINS = `${domain}/tickets/coins`;

// reschdule

// campaign

export const CREATE_CAMPAIGN = `${domain}/campaign/create_with_proposal`;
export const SAVE_CAMPAIGN_FOR_LATER = `${domain}/campaign/save_for_later`;
export const SEND_NEW_PROPOSALS = `${domain}/campaign/send_new_proposals`;

export const CREATE_BRAND_SESSION = `${domain}/campaign/create_session`;
export const UPDATE_BRAND_SESSION_STATUS = `${domain}/campaign/update_session_status`;
export const BRAND_SESSION_ADD_QUEST = `${domain}/campaign/session_add_quest`;
export const GET_PRIMARY_SESSION = `${domain}/campaign/primary_session`;
export const CAMPAIGN_REJECT_PROPOSAL = `${domain}/campaign/reject_proposal`;
export const CAMPAIGN_ACCEPT_PROPOSAL = `${domain}/campaign/accept_proposal`;
export const CAMPAIGN_CANCEL_PROPOSAL = `${domain}/campaign/cancel_proposal`;
export const PROPOSAL_ADD_SCHEDULE = `${domain}/campaign/proposal_add_schedule`;
export const PROPOSAL_REMOVE_SCHEDULE = `${domain}/campaign/proposal_remove_schedule`;
export const PROPOSAL_CONFIRM_SCHEDULE = `${domain}/campaign/proposal_confirm_schedule`;
export const PROPOSAL_COMPLETE = `${domain}/campaign/proposal_complete`;
export const PROPOSAL_SUBMIT_REVIEW = `${domain}/campaign/proposal_submit_review`;
// tournament
export const CREATE_TOURNAMENT = `${domain}/tournament/create`;
export const PUBLISH_TOURNAMENT = `${domain}/tournament/publish`;
export const TOURNAMENT_INVITE = `${domain}/tournament/invite`;
export const TOURNAMENT_REMOVEINVITE = `${domain}/tournament/removeInvite`;
export const TOURNAMENT_TEAMSUP = `${domain}/tournament/teamsUp`;
export const TOURNAMENT_ROUND_CREATE = `${domain}/tournament/round_create`;
export const TOURNAMENT_ROUND_ADD_PLAYER = `${domain}/tournament/round_addPlayer`;
export const TOURNAMENT_ROUND_REMOVE_PLAYER = `${domain}/tournament/round_removePlayer`;
export const TOURNAMENT_ROUND_ADDPLAN = `${domain}/tournament/round_addPlan`;
export const TOURNAMENT_ROUND_REMOVEPLAN = `${domain}/tournament/round_removePlan`;
export const TOURNAMENT_CLOSEREGISTRATION = `${domain}/tournament/close`;
export const TOURNAMENT_UPDATEROUNDINDEX = `${domain}/tournament/updateRoundIndex`;
export const TOURNAMENT_CONFIRMROUND = `${domain}/tournament/confirmRound`;
export const TOURNAMENT_CHECKIN = `${domain}/tournament/checkIn`;
export const TOURNAMENT_JOINASSUB = `${domain}/tournament/join_as_sub`;
export const TOURNAMENT_JOINWITHCOINS = `${domain}/tournament/join_with_coins`;

// stream
export const GET_TWITCH_STREAMING = `${domain}/twitch/get_game_streaming_detail`;

//liveShoppingEvent 

export const SEND_EVENT_CHAT = `${domain}/liveShoppingEvent/send_message`;
export const JOIN_EVENT = `${domain}/liveShoppingEvent/join_event`;
export const LEAVE_EVENT = `${domain}/liveShoppingEvent/leave_event`;

// liveshopping 
export const LIVESHOPPING_GAME_CHECKOUT =`${domain}/liveShopping/checkout_game`;
export const LIVESHOPPING_SESSION_STATUS = `${domain}/liveShopping/get_session_status`;
export const LIVESHOPPINGEVENT_GAME_CHECKOUT = `${domain}/liveShopping/checkout_game_from_event`;
export const LIVESHOPPINGLIST_EMBEDDED = `${domain}/liveShopping/checkout_game_from_event`;

//service bot
export const GAME_ASSISTANT_NEW_CHAT = `${serviceBotDomain}/gameAssistant/new_chat`;
export const GAME_ASSISTANT_NEW_MESSAGE = `${serviceBotDomain}/gameAssistant/new_message`;
export const GAME_ASSISTANT_CLEAR_CHAT = `${serviceBotDomain}/gameAssistant/clear_chat`;
export const GENERAL_ASSISTANT_NEW_CHAT = `${serviceBotDomain}/gameAssistant/general_new_chat`;

export const PROMOTE_GAME_ASSISTANT_CHAT =  `${serviceBotDomain}/gameAssistant/promote_chat`;

//steam game detail
export const GET_STEAM_GAME_RAW = `${domain}/steam-games/getGameRaw`;
export const GET_INFLUENCER_RAW = `${domain}/twitch/influencer_raw`;

// 
export const UP_VOTE_GAME = `${domain}/steam-games/game_upVote`;
export const UN_VOTE_GAME = `${domain}/steam-games/game_unVote`;

/* export const RESCHEDULE_QUEST = `${rescheduleDomain}/quests/reschedule`; */