import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import { BellOutlined, AreaChartOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { logout } from '../action/user';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

const PrivateNavBar = ({ user, activeTab, isDarkMode, logout }) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const permission_level = user.profile?.permission_level ?? 0;
  const token_count = user.profile?.token_count ?? 0;

  // Navigation functions
  const toMyProfile = () => {
    const { id } = user;
    history.push(`/profile/${id}`);
  };


  const toProfile = () => {
    if (user.loggedIn) {
      history.push("/myAccount/information");
    } else {
      history.push("/login");
    }
  };

 

  const logoutUser = () => {
    logout();
    history.push("/");
  };

  // Other navigation functions...

  const menu = (
    <StyledMenu>
      <Menu.Item onClick={toProfile}>
        <MenuBox>
          <Item>My Account</Item>
          <img
            src="/assets/nav-profile.png"
            alt=""
            style={{
              height: "20px",
              width: "20px",

            }}
          />
        </MenuBox>
      </Menu.Item>
      {permission_level === 2 && (
          <Menu.Item
            onClick={() => {
              history.push("/tournament_admin/tournaments")
            }}
          >
            <MenuBox>
            <Item>Tournament Admin</Item>
            <AreaChartOutlined  style={{
              fontSize:20
            }}/>
            </MenuBox>
          </Menu.Item>
        )}
      <Menu.Item
          onClick={logoutUser}
        >
          <MenuBox>
          <Item>Logout</Item>
          <img
            src="/assets/nav-logout.png"
            alt=""
            style={{
              height: "20px",
              width: "20px",

            }}
          />
          </MenuBox>
        </Menu.Item>
    </StyledMenu>
  );

  return (
    <Container isDarkMode={isDarkMode}>
      <Left>
        {
          isMobile ? <>
          <Logo onClick={() => history.push('/')} src="/assets/aq_logo.png" />
          <NavButton onClick={() => {
            history.push("/launches")
          }} isActive={activeTab === 'launches'} isDarkMode={isDarkMode}>LAUNCHES</NavButton>
          {/* <NavButton onClick={() => {
            history.push("/")
          }} isActive={activeTab === 'liveShopping'} isDarkMode={isDarkMode}>DROPS</NavButton> */}
          <NavButton onClick={() => {
            history.push("/liveShoppingGames")
          }} isActive={activeTab === 'currentDeals'} isDarkMode={isDarkMode}>DEALS</NavButton>
          </> : <>
          <Logo onClick={() => history.push('/')} src="/assets/aq_logo.png" />
          <NavButton onClick={() => {
            history.push("/launches")
          }} isActive={activeTab === 'launches'} isDarkMode={isDarkMode}>LAUNCHES</NavButton>
          <NavButton onClick={() => {
            history.push("/liveShoppingEvents")
          }} isActive={activeTab === 'liveShopping'} isDarkMode={isDarkMode}>DROPS</NavButton>
          <NavButton onClick={() => {
            history.push("/liveShoppingGames")
          }} isActive={activeTab === 'currentDeals'} isDarkMode={isDarkMode}>DEALS</NavButton>
          <NavButton onClick={() => {
              history.push("/exchange");
            }} isActive={activeTab === 'exchange'} isDarkMode={isDarkMode}>EXCHANGE</NavButton>
            <NavButton onClick={toMyProfile} isActive={activeTab === 'profile'} isDarkMode={isDarkMode}>PROFILE</NavButton>
          </>
        }
      </Left>
      <Right>
        {
          !isMobile ? <><GetMore onClick={() => {
            history.push("/multiplier")
          }}>Premium</GetMore>
            <TokenCount isDarkMode={isDarkMode}>
              <img
                src="/assets/ic_mytokens.png"
                alt=""
                style={{
                  height: "16px",
                  width: "20px",
                  marginRight: "8px",
                }}
              />
              {token_count}
            </TokenCount>
            <Dropdown
              overlay={menu}
              placement="bottomLeft"
              onClick={(e) => e.preventDefault()}
              trigger={["click"]}
            >
              <Profile isDarkMode={isDarkMode} />
            </Dropdown>
          </> :  <Dropdown
            overlay={menu}
            placement="bottomLeft"
            onClick={(e) => e.preventDefault()}
            trigger={["click"]}
          >
            <Profile isDarkMode={isDarkMode} />
          </Dropdown>
        }
      </Right>
    </Container>
  );
};

const MenuBox = styled.div`
width: 225px;
height: 54px;
display: flex;
align-items: center;
justify-content: space-between;
padding:0 32px 0 16px;
`;

const NotifiTitle = styled.div`
display:flex;
width:100%;
justify-content:space-between;
font: normal normal bold 14px SF PRO, serif;
margin-bottom:8px;
padding-right:12px;
`;

const Message = styled.div`
font: normal normal bold 14px SF PRO, serif;
padding-right:12px;
width:100%;
`;

const Title = styled.div`
width:300px;
height:36px;
font: normal normal bold 18px/36px SF PRO, serif;
`;

const StyledMenu = styled(Menu)`
  width: 245px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  padding: 8px 8px;
`;

const Item = styled.div`
  font: normal normal 600 18px SF PRO, serif;
  letter-spacing: 0px;
  color: #484848;
`;

const Container = styled.div`
  height: 78px;
  padding: 15px 40px;
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 7px #57575729;
  justify-content: space-between;
  align-items:center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  ${p => p.isDarkMode && `background: #18181B 0% 0% no-repeat padding-box;`}
  @media (max-width: 768px) {
    padding:12px;
   }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: auto;
  height: 40px;
  margin-right: 48px;
  cursor: pointer;

  @media (max-width: 768px) {
   margin-right:16px;
   height:30px;
  }
`;

const NavButton = styled.div`
  width: auto;
  margin-right: 24px;
  font: normal normal 600 20px SF PRO, serif;
  color: #484848;
  cursor: pointer;
  ${(props) =>
    props.isDarkMode && `color: #FAFAFA;`}

  ${(props) =>
    props.isActive && `color: #F72375;`}
`;

const Profile = styled.img`
  background: url("/assets/adaptive-icon-large.png") no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  cursor: pointer;
  ${(props) =>
    props.isDarkMode && `border:1px solid #18181B`}
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  border-left: 2px solid #bfbfbf;
  padding-left: 32px;

  @media (max-width: 768px) {
    padding:12px;
   }
`;

const GetMore = styled.div`
  width: 132px;
  height: 32px;
  background: linear-gradient(
    102deg,
    rgba(247, 133, 104, 1) 0%,
    rgba(248, 97, 111, 1) 99%
  );
  border-radius: 7px;
  font: normal normal bold 18px/32px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  margin-right: 32px;
`;

const TokenCount = styled.div`
  font: normal normal bold 16px SF PRO, serif;
  letter-spacing: 0px;
  color: #484848;
  margin-right: 32px;
  ${(props) =>
    props.isDarkMode && `color: #FAFAFA;`}
`;


const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateNavBar);
