import useIsMobile from "../../util/isMobile";
import EventAfterStartWeb from "./eventAfterStartWeb";
import EventAfterStartMobile from "./eventAfterStartMobile";

const EventAfterStart = ({event, gameDetails, leaveEvent}) => {

  const isMobile = useIsMobile()
  if(isMobile){
    return <EventAfterStartMobile event={event} gameDetails={gameDetails} leaveEvent={leaveEvent} /> 
  }else {
    return <EventAfterStartWeb event={event} gameDetails={gameDetails} leaveEvent={leaveEvent} /> 
  }
}



export default EventAfterStart;