import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import * as API from "../../util/api";
import Loading from "../../util/loading";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import PublicNavBar from "../../nav/publicNavBar";
import PrivateNavBar from "../../nav/privateNavBar";
import CurrentGames from "./currentGames";
import { Mixpanel } from '../../util/mixpanel';

const InfluencerPromote = ({match}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const [influencer, setInfluencer] = useState([]);

  useEffect(() => {
    const influencer = match?.params?.name;
    if (influencer) {
      Mixpanel.track("InfluencerPromote Page View", { name: influencer }); // Track the page view with the influencer name property
    }
    const fetchInfluencer = async() => {
      const endpoint = API.GET_INFLUENCER_RAW;
      try {
        const res = await axios.get(endpoint, {
          params:{
            name:influencer
          }
        });
        if(res.status === 200){
          console.log(res.data)
          const detail = res.data.detail;
          setInfluencer(detail);
        }
      }catch(err){
        console.log(err);
      }
    }

    fetchInfluencer()
  }, [match]);

  return <Container>
    {loggedIn ? <PrivateNavBar isDarkMode={true} /> : <PublicNavBar isDarkMode={true} />}
    <Top>
      {
        influencer && <>
          <div>
            <Logo src={influencer.profile_image_url} alt="profile"/>
            <Title>
              {influencer.display_name}
            </Title>
            <TopContext>
              {influencer.description}
            </TopContext>
          </div>
          <PlayContainer>
          <ReactPlayer
          url={`https://www.twitch.tv/${influencer.login}`}
          playing={true}
          style={{maxHeight:'279px', maxWidth:'100%'}}
          controls
        />
          </PlayContainer>
        </>
      }
    </Top>
    <Content>
      <CurrentGames />
    </Content>
  </Container>
}

const Container = styled.div`
  width: 100%;
  background:#1C1D20 0% 0% no-repeat padding-box;
  min-height:calc(100vh);
  padding-top:78px;
  overflow-y:auto;
  position:relative;

  @media (max-width: 768px) {
    width: 100%; // Adjust for smaller screens
    padding-top:0px;
    margin-top:54px;
    overflow-x: hidden;
  }
`;

const Top = styled.div`
width: 100%;
height: auto;
margin-bottom:36px;
background-size: cover;
background-position: center;
border: 1px solid transparent; 
background-color:black;
padding:36px 0px;

display:flex;
align-items:center;
justify-content:center;
gap:2rem;

@media (max-width: 768px) {
  width: 95%; // Adjust for smaller screens;
  margin:0 auto;
  padding:36px 0 24px;
  height:auto;
  flex-direction:column;
}
`;

const Logo = styled.img`
height:60px;
width:60px;
border-radius:50%;
margin-bottom:16px;
`;

const TopContext = styled.div`
color: #FFF;
font-family: SF Pro;
font-size: 18px;
font-style: normal;
font-weight: 590;
line-height: normal;
width:400px;
max-width:300px;

@media (max-width: 768px) {
  width:auto;
}
`;

const Title = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
margin-bottom:16px;
`;

const PlayContainer = styled.div`
height:279px;
@media (max-width: 768px) {
  width:95%;
  height:auto;
}
`;
const Content = styled.div`
width:1260px;
margin:0px auto 48px;

@media (max-width: 768px) {
  width: 95%; // Adjust for smaller screens;
  margin-top:48px;
}
`;

export default withRouter(InfluencerPromote);