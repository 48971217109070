import React, { useEffect } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
// import { ConnectedRouter } from 'react-router-redux';
import { connect } from "react-redux";

//Action
import { updateEvents, updateProfile } from "./action/user";
import { getPartners } from "./action/partner";
import { getEvents } from "./action/event.js";

//Router
import HomePageEntry from "./home/homePageEntry";
import Login from "./home/login";
import Multiplier from "./pays/multiplier";
import LinkAccount from "./home/linkAccount";
import LinkAccountSuccess from "./home/linkAccountSuccess";
import Quest from "./home/quest";
import Profile from "./home/profile";

import Firebase from "./util/firebase";
import Join from "./home/join";
import Exchange from "./home/exchange";

import CreatorQuestsCustomer from "./home/creatorquests/creatorQuestCustomer";

//pro league
import CreatorProLeagueEntry from "./home/proleague/create";
import ProLeagueCustomer from "./home/proleague/proLeagueCustomer";
import ProLeagueControl from "./home/proleague/proLeagueControl";



/* import { isMobile } from "react-device-detect"; */
import MobileVersion from "./mobileVersion";
import GetTicketSuccess from "./pays/getTicketSuccess";
import GetTicketSuccessCoins from "./pays/getTicketSuccessCoins";

//brand_session
import BrandSessionCustomer from "./home/brandSession/index";

// tournament
import TournamentAdmin from "./tournament/index";
import TournamentCustomer from "./home/tournamentCustomer/tournament";
import TournamentRound from "./home/tournamentCustomer/round";
import TournamentQuest from "./home/tournamentCustomer/quest";
import Tournaments from "./home/tournamentCustomer/index";

// game live shopping
import LiveShoppingGames from "./home/liveShopping";
import LiveShoppingGameDetail from "./home/liveShopping/gameDetailPage.js";
import LiveShoppingOrderStatus from "./home/liveShopping/liveShoppingOrder";

// game live shopping event
import LiveShoppingEvents from "./home/liveShoppingEvent/index.js";
import EventDetail from "./home/liveShoppingEvent/eventDetail.js";

import Account from "./profile/index";
import EventPaymentStatus from "./home/liveShoppingEvent/eventPaymentStatus.js";

import { isMobile } from "react-device-detect";

// publisherPromote
import GamePromote from "./botPromote/index.js";
import InfluencerPromote from "./home/liveShopping/influencerPromote.js";

import Launches from "./home/launches/index.js";


const HHRouter = ({ user, updateProfile, getPartners }) => {

  useEffect(() => {
    let unsubscribe;
    const getProfileUpdate = async () => {
      if (user.loggedIn) {
       unsubscribe =  Firebase.firestore()
          .collection('users')
          .doc(user.id)
          .onSnapshot((doc) => {
            const data = doc.data();
            updateProfile(data)
          });
      }
    };

    

    getProfileUpdate();
    getPartners()

    return () => {
      if (unsubscribe) {
          unsubscribe();
      }
  };
    /* getEvents()
    getPartners();  */// Assuming getPartners does not depend on user being logged in
  }, [user.loggedIn, user.id]);

  // Render method content goes here
  return (
    <BrowserRouter>
      <Switch>
          <Route exact path="/" render={() => {
            return <HomePageEntry />
          }} />
          <Route exact path="/signup"  render={() => {    
              return <Join />
          }}/>
          <Route exact path="/login" render={() => {
            
              return <Login />
          }}/>
          <Route exact path="/multiplier" component={Multiplier} />
        

          <Route exact path="/getTicketsSuccess"  render={() => {
              if(isMobile){
                return <MobileVersion />
              }else {
                return <GetTicketSuccess />
              }
            }}/>
            <Route exact path="/getTicketsSuccessCoins"  render={() => {
              if(isMobile){
                return <MobileVersion />
              }else {
                return <GetTicketSuccessCoins />
              }
            }}/>
            <Route exact path="/linkAccount"  render={() => {
              if(isMobile){
                return <MobileVersion />
              }else {
                return <LinkAccount />
              }
            }}/>
             <Route exact path="/exchange" component={Exchange} />
          <Route exact path="/linkAccountSuccess"  render={() => {
              if(isMobile){
                return <MobileVersion />
              }else {
                return <LinkAccountSuccess />
              }
            }}/>

            <Route exact path="/quest" component={Quest} />

            <Route exact path="/profile/:id" render={() => {
            if(isMobile){
              return <MobileVersion />
            }else {
              return <Profile />
            }
          }}x/>

           
            <Route exact path="/creatorProLeagueEntry"  render={() => {
              if(isMobile){
                return <MobileVersion />
              }else {
                return <CreatorProLeagueEntry />
              }
            }}/>
         
            <Route exact path="/creatorQuestCustomer/:id"  render={() => {
              if(isMobile){
                return <MobileVersion />
              }else {
                return <CreatorQuestsCustomer />
              }
            }}/>
             <Route exact path="/proLeagueCustomer/:id"  render={() => {
              if(isMobile){
                return <MobileVersion />
              }else {
                return <ProLeagueCustomer />
              }
            }}/>
             <Route exact path="/proLeagueControl/:id"  render={() => {
              if(isMobile){
                return <MobileVersion />
              }else {
                return <ProLeagueControl />
              }
            }}/>
          <Route path="/tournament_admin" component={TournamentAdmin} />
          <Route path="/tournament/:id" component={TournamentCustomer} />
          <Route path="/tournamentRound/:id" component={TournamentRound} />
          <Route path="/tournamentQuest/:id" component={TournamentQuest} />
          <Route path="/tournaments" component={Tournaments} />
          <Route path="/brandSession/:id" component={BrandSessionCustomer} />
          <Route path="/launches" component={Launches} />
          <Route path="/liveShoppingGames" component={LiveShoppingGames} />
          <Route path="/liveShoppingGame/:id" component={LiveShoppingGameDetail} />
          <Route path="/liveShoppingPaymentSuccess" component={LiveShoppingOrderStatus} />
          <Route path="/liveShoppingEvents" component={LiveShoppingEvents} />
          <Route path="/liveShoppingEvent/:id" component={EventDetail} />
          <Route path="/liveShoppingEventPaymentSuccess" component={EventPaymentStatus} />
          <Route path="/myAccount" component={Account} />
          <Route path="/gamePromote/:id" component={GamePromote} />
          <Route path="/influencerPromote/:name" component={InfluencerPromote} />
        </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = { updateProfile, getPartners, getEvents, updateEvents};

export default connect(mapStateToProps, mapDispatchToProps)(HHRouter);

