import React, { useState, useEffect,useMemo } from 'react';
import { Divider,  } from "antd";
import styled from 'styled-components';
import { useSelector } from "react-redux";
import Firebase from "../../util/firebase";
import ChatBox from './chatBox';


const ChatBot = () => {
  const [allChat, setAllChat] = useState([]);
  const [bots, setBots] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [currentBot, setCurrentBot] = useState(null);

  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  const currentMessageUsage = profile?.bot_messages??0;

  useEffect(() => {
    let listener = null;
    const fetchChat = async () => {
      if(userId){
        listener = Firebase.firestore()
        .collection("game_assistant_chat")
        .where("userId", "==", userId)
        .where("status", "==", 1)
        .onSnapshot(querySnapshot => {
          let chats = [];
          querySnapshot.forEach(function (doc) {
            const obj = {
              ...doc.data(),
             chatId: doc.id,
            };
            chats.push(obj);
          });
          setAllChat(chats);
        },
        error => {
          console.log(error);
        }
    )
      }
      
  }
  fetchChat();
  return () => {
    if(listener){
      listener();
    }
  }
  }, [userId]);

  useEffect(() => {
    let listener = null;
    const fetchBots = async () => {
      listener = Firebase.firestore()
        .collection("game_assistant_bot")
        .onSnapshot(querySnapshot => {
          let bots = [];
          querySnapshot.forEach(function (doc) {
            const obj = {
              ...doc.data(),
             botId: doc.id,
            };
            bots.push(obj);
          });
          setBots(bots);
          const defaultBot = bots.find(item => item.type === 'platform')
          if(defaultBot){
            setCurrentBot(defaultBot.botId)
          }else {
            const defaultBotId = bots[0].botId;
          setCurrentBot(defaultBotId);
          }
        },
        error => {
          console.log(error);
        }
    )
  }
  fetchBots()
  return () => {
    if(listener){
      listener();
    }
  }
  }, [userId]);

  
  const serviceList = useMemo(() => {
    const list = bots.map((item) => {
      const chat = allChat.find((chat) => chat.modelId === item.modelId)
      if(chat){
        return {
          ...item,
          messages:chat.messages,
          chatId:chat.chatId
        }
      }else {
        return {
          ...item,
  
        }
      }
    });

    list.sort((a, b) => {
      if (a.type === 'platform' && b.type !== 'platform') {
        return -1; // a comes first
      }
      if (a.type !== 'platform' && b.type === 'platform') {
        return 1; // b comes first
      }
      return 0; // no change in order
    });
    return list;
  }, [bots, allChat]);

  const selectedBot = useMemo(() => {
    if(currentBot){
      return serviceList.find(item => item.botId === currentBot);
    }
  }, [serviceList, currentBot]);


  return (
    <Bot>
      <ChatIcon src="/assets/chat_bot_icon.svg" alt="chat_bot" 
    onClick={() => {
      setIsChatOpen(!isChatOpen)
    }}
    /> 

      <Container isChatOpen={isChatOpen}>
        <div style={{display:'flex', width:'100%', height:'100%'}}>
          <div style={{display:'flex', flexDirection:'column'}}>
          <List>
            {
              serviceList.map((item) => {
                return <>
                  <ChatName active={currentBot === item.botId} 
                  isPlatform={item.type === 'platform'}
                  onClick={() => {
                    setCurrentBot(item.botId)
                  }}>
                   {item.gameName || item.name}
                  </ChatName>
                </>
              })
            }
          </List>
          <MessagesCount style={{color:'white'}}>
            Usage Limit: {currentMessageUsage}/100
          </MessagesCount>
          </div>
          <div style={{flexGrow:1}}>
          {
            currentBot && <ChatBox chat={selectedBot} closeChat={() => {
              setIsChatOpen(false);
            }}
            key={currentBot}
            />
          }
          </div>
        </div>
      </Container>
    </Bot>
  );
};


const Bot = styled.div`
`;


const ChatIcon = styled.img`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  height: 50px;
  width: 50px;
  cursor: pointer;

  @media (max-width: 760px) {
    width: 32px;
    height:32px;
    right:18px;
    bottom:18px;
  }

`;


const Container = styled.div`
  position: fixed;
  bottom: 78px; /* Position above the button */
  right: 24px;
  width: auto;
  height: 500px;
  background-color: #000;
  border-radius: 10px;
  display: ${props => props.isChatOpen ? 'block' : 'none'};

  .ant-divider-horizontal {
    border-top: 1px solid rgb(224, 224, 224);
    margin: 0px;
  }

  .ant-divider-vertical {
    border-left: 1px solid #fff;
    height: 100%;
  }

  @media (max-width: 760px) {
    width: 100%;
    right:0px;
    bottom:54px;
    border-radius:0px;
  }
`;

const List = styled.div`
width:144px;
height:468px; 
background: #2B2D31;
 padding:4px 8px;, 
 border-radius: 10px 0px 0px 0px;

 @media (max-width: 760px) {
  width: 100%;
  flex-grow:1;
  border-radius:0px;
}
`;

const ChatName = styled.div`
color:white;
${p => p.active && `border-radius: 2px;
background: #404248;`}
${p => p.isPlatform && `color: #F72375;`}
padding:4px;
margin:4px 0px;
`;

const MessagesCount = styled.div`
height:32px;
line-height:32px;
font-family: SF Pro Display;
font-size: 14px;
text-align:center;
background: #2B2D31;

@media (max-width: 760px) {
  height:auto;
}
`;


export default ChatBot;





