import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import * as API from "../../util/api";
import Firebase from "../../util/firebase";
import PublicNavBar from "../../nav/publicNavBar";
import PrivateNavBar from "../../nav/privateNavBar";
import { useSelector } from "react-redux";
import GameCard from "./component/gameCard";
import QuestCard from "../creatorquests/questCard";
import { Divider, Skeleton } from "antd";
import DetailModal from "./component/detailModal";
import Loading from "../../util/loading";
import ChatBot from "../serviceBot";

const Launches = ({history}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const { items: events} = useSelector((state) => state.event);
  const [todayGames, setTodayGames] = useState([]);
  const [yesterdayGames, setYesterdayGames] = useState([]);
  const [lastWeekGames, setLastWeekGames] = useState([]);

  const [modelOpen, setModalOpen] = useState(true);
  const [gameDetail, setGameDetail] = useState(null);

  const [count, setCount] = useState({
    today:false,
    yesterday:false,
    lastWeek:false
  });

  function toLocalDateString(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // JavaScript中月份是从0开始计数的
    const day = date.getDate();
    // 使用模板字符串格式化日期，并确保月份和日期为两位数
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  }



useEffect(() => {
  const today = new Date();
  const todayString = toLocalDateString(today) // 将今天的日期转换为YYYY-MM-DD格式

  const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1); // 减去一天得到昨天的日期
const yesterdayString = toLocalDateString(yesterday);

//上周
const dayOfWeek = today.getDay(); // 当天是周几（0代表周日，6代表周六）

// 上周开始（上周周日）
const weekStart = new Date(today);
weekStart.setDate(today.getDate() - dayOfWeek - 7); // 先回到本周周日，再减7天到上周周日
const weekStartString = toLocalDateString(weekStart);

// 上周结束（上周周六）
const weekEnd = new Date(weekStart);
weekEnd.setDate(weekStart.getDate() + 6); // 从上周开始日期加上6天得到上周结束日期（周六）
const weekEndString = toLocalDateString(weekEnd);

  const todayQuery = Firebase.firestore().collection('game_voting')
                         .where('launchDate', '==', todayString);
                         todayQuery.onSnapshot((querySnapshot) => {
                          const games = querySnapshot.docs.map(doc => ({
                            id: doc.id,
                            ...doc.data(),
                          }));
                          games.sort((a, b) => b.upVoteCount - a.upVoteCount);
                          setTodayGames(games); // 实时更新state中的今日游戏数据
                          setCount(prev => ({...prev, today:true}));
                        }, error => {
                          console.error("Error fetching today's games:", error);
    }); 
    
    const yesterdayQuery = Firebase.firestore().collection('game_voting')
                           .where('launchDate', '==', yesterdayString) // 按upVoteCount降序排序
                           

    yesterdayQuery.get().then((querySnapshot) => {
      const games = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      games.sort((a, b) => b.upVoteCount - a.upVoteCount);
      const displayGames = games.slice(0,5);
      setYesterdayGames(displayGames); // Update state with yesterday's games
      setCount(prev => ({...prev, yesterday:true}));
    }).catch(error => {
      console.error("Error fetching yesterday's games:", error);
    });


      const lastWeekQuery = Firebase.firestore().collection('game_voting')
      .where('launchDate', '>=', weekStartString)
      .where('launchDate', '<=', weekEndString)// 按upVoteCount降序排序

      lastWeekQuery.get().then((querySnapshot) => {
      const games = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      }));
      games.sort((a, b) => b.upVoteCount - a.upVoteCount);
      const displayGames = games.slice(0,5)
      setLastWeekGames(displayGames);
      setCount(prev => ({...prev, lastWeek:true})); // Update state with this week's games
      });
}, []);

  const openDetailModal = (gameId, position, ableToVote) => {
    setGameDetail({gameId, position, ableToVote});
    setModalOpen(true);
  }
  const currentGame = useMemo(() => {
    const game = [...todayGames, ...yesterdayGames, ...lastWeekGames].find((game) => game.id === gameDetail?.gameId);
    if(game){
      return {
        ...game,
        position: gameDetail?.position,
        ableToVote: gameDetail?.ableToVote
      }
    }else {
      return null
    }
  }, [gameDetail, todayGames, yesterdayGames, lastWeekGames]);

  return <Container>
    {loggedIn ? <PrivateNavBar isDarkMode={true} activeTab="launches"/> : <PublicNavBar isDarkMode={true} activeTab="launches"/>}
    <Content>
      <Left>
        <Title>
          Games Launching Today
        </Title>
        {
          count.today ? <> 
            {
           todayGames.map((game, index) => {
            return <GameCard key={game.id} game={game} upVoteEnabled={true} position={index}
            openDetailModal={openDetailModal}
            />
          })
          }
          </>: <Skeleton active style={{marginBottom:16}}/>
        }
  

        <Title >
          Yesterday's Top Games
        </Title>
        {
          count.yesterday ? <>
           {
          yesterdayGames.map((game, index) => {
            return <GameCard key={game.id} game={game} upVoteEnabled={false} 
            position={index}
            openDetailModal={openDetailModal}
            />
          })
        }
          </> :< Skeleton active style={{marginBottom:16}}/>
        }
       

        <Title >
          Last Week's Top Games
        </Title>
        {
          count.lastWeek ? <>

                    {
                      lastWeekGames.map((game, index) => {
                        return <GameCard key={game.id} game={game} upVoteEnabled={false} position={index}
                        openDetailModal={openDetailModal}
                        />
                      })
                    }

          </>: <Skeleton active style={{marginBottom:16}}/>
        }

      </Left>
      <Right>
          <QrCode src="/assets/qr-code.png" />
          <QrCodeText><img src="/assets/aq_black_icon.png" style={{width:"32px", height:"32px", marginRight:8}}
          alt="logo"
          />
            ArcadeQuest iOS App
          </QrCodeText>
          <QrCodeSubText>Get exclusive access to new games</QrCodeSubText>
          <DropContainer>
              <SubTitle>
                Drops
              </SubTitle>
              {
                events.map((event) => {
                  return <DropImg key={event.id} src={event.backgroundImage} onClick={() => {
                    history.push(`/liveShoppingEvent/${event.eventId}`)
                  }}>
                  </DropImg>
                })
              }
          </DropContainer>
      </Right>
    </Content>
    {
      currentGame &&  <DetailModal 
      game={currentGame}
      modalOpen={modelOpen}
      closeModal={() => {
        setModalOpen(false)
      }}
      />
    }
   <ChatBot />
  </Container>

}

const Container = styled.div`
  width: 100%;
  background:#1C1D20 0% 0% no-repeat padding-box;
  min-height:calc(100vh);
  padding-top:78px;
  overflow-y:auto;
  position:relative;

  @media (max-width: 768px) {
    width: 100%; // Adjust for smaller screens
    padding-top:0px;
    margin-top:54px;
    overflow-x: hidden;
  }

`;

const Content = styled.div` 
display:flex;
margin-top:48px;
width:auto;
justify-content:center;

@media (max-width: 768px) {
  display: flex; // or 'block' depending on desired layout
  flex-direction: column; // only needed if using flex
  align-items:center;
  width:95%;
  margin:48px auto;
}
`;

const Left = styled.div`
display:flex;
flex-direction:column;
margin-right:4rem;
width:630px;

@media (max-width: 768px) {
  margin-right:0;
  margin-bottom:2rem;
}
`;

const Right = styled.div`
display:flex;
flex-direction:column;

@media (max-width: 768px) {
  width:95%;
 margin:2rem auto;
}
`;

const Title = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom:24px;
`;

const QrCode = styled.img`
width:164px;
height:164px;


@media (max-width: 768px) {
  width:100%;
  height:auto;
  
}
`;

const QrCodeText = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin:12px 0px;
`;

const QrCodeSubText = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const DropContainer = styled.div`
width:232px;
border-top:1px solid #FFF;
margin-top:24px;
padding-top:24px;
display:flex;
flex-direction:column;

@media (max-width: 768px) {
  width:100%;
}
`;

const SubTitle = styled.div` 
color: #FFF;
font-family: "SF Pro Display";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
margin-bottom:16px;
`;

const DropImg = styled.img`
width:232px;
margin-bottom:24px;

@media (max-width: 768px) {
  width:100%;
}
`;

export default withRouter(Launches)