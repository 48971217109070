import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import axios from "axios";
import * as API from "../../../util/api";
import { message } from "antd";
import useIsMobile from "../../../util/isMobile";

const GameCard = ({game, upVoteEnabled, position, openDetailModal}) => {
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  const voteCount = game.upVoteCount? game.upVoteCount : 0;
  const voted = userId ? game.upVotes.find(item => item.userId === userId) : false;
  const isMobile = useIsMobile();

  const upVoteGame = async () => {
    try {
      const url = API.UP_VOTE_GAME;
    const res = await axios.post(url, {
      gameId:game.id,
      userId,
      userName: profile.first_name
    });
    if(res.status === 200){
      message.success("Upvote success")
    }else {
      message.error("Failed to upvote, please try again")
    }
    }catch {
      message.error("Failed to upvote, please try again")
    }
  }

  const unVoteGame = async () => {
    try {
      const url = API.UN_VOTE_GAME;
    const res = await axios.post(url, {
      gameId:game.id,
      userId
    });
    if(res.status === 200){
      message.success("Unvote success")
    }else {
      message.error("Failed to unvote, please try again")
    }
    }catch {
      message.error("Failed to unvote, please try again")
    }
  }
  return (
    <GameCardContainer onClick={() =>{
      if(!isMobile){
        openDetailModal(game.id, position, upVoteEnabled)}
    } }>
      <GameCardImg src={game.bannerUrl} />
      <GameDetailContainer>
        <GameTitle>{game.name}</GameTitle>
        <div style={{display:"flex", flexWrap:"wrap", gap:"4px"}}>
          {game.genres.map((genre) => (
           <Genre>
              • {genre}
            </Genre>
          ))}
        </div>
        <Price>
          {game.regular_price? `USD ${game.regular_price}` : "Free"}
        </Price>
      </GameDetailContainer>
      <VoteBox voted={voted} ableToVote={upVoteEnabled} onClick={(event) => {
          event.stopPropagation();
          if(voted){
            if(upVoteEnabled){
              debounce(() => unVoteGame(), 500)();
            }
          }else if(!upVoteEnabled) {
            message.warn("Upvote is disabled")
          }else {
            if(loggedIn){
              debounce(() => upVoteGame(), 500)();
            }else {
              message.error("Please login first")
            }
          }
      }}>
        <TriangleUp voted={voted}/>
        <VoteCount>
          {voteCount}
        </VoteCount>
      </VoteBox>
    </GameCardContainer>
  )
}

const GameCardContainer = styled.div`
  display: flex;
  margin-bottom:24px;
  align-items: center;
  @media (max-width: 768px) {
    width:95%;
    margin:24px auto;
  }
`;

const GameCardImg = styled.img`
width:149px;
margin-right:16px;
border-radius: 10px;
height:70px;
cursor:pointer;
`;

const GameDetailContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
width:428px;
height:100%;
height:70px;
@media (max-width: 768px) {
  width:auto;
}
cursor:pointer;
`;

const GameTitle = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;

const Genre = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const Price = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const VoteBox = styled.div`
width: 38px;
height: 38px;
flex-shrink: 0;

border-radius: 4px;
border: 1px solid #F72375;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-left:auto;

${p => p.voted && `background: #F72375;`}
${p => p.ableToVote ? `cursor:pointer;`:`cursor:not-allowed;`}
`;

const TriangleUp = styled.div`
  width: 0;
  height: 0;
  border-left: 6.5px solid transparent;
  border-right: 6.5px solid transparent;
  ${p => p.voted ? `border-bottom: 8px solid #FFF;`:`border-bottom: 8px solid #F72375;`}
`;

const VoteCount = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top:2px;
`;

export default GameCard;