import * as ActionTypes from "./type";
import Firebase from "../util/firebase";

export const getPartners = () => async (dispatch) => {
  Firebase.firestore()
    .collection("liveShoppingEvent")
    .where('status', 'in', [0,1])
    .onSnapshot((snapshot) => {

/*       snapshot.docChanges().forEach(change => {
        if (change.type === "modified") {
            console.log("Modified doc:", change.doc.data(), "Metadata:", change.doc.metadata);
        }
    }); */
      let resultArray = [];

      snapshot.forEach((doc) => {
        const obj = {
          eventId: doc.id,
          ...doc.data()
        };
        resultArray.push(obj);
      });

      dispatch({
          type: ActionTypes.SAVE_EVENT,
          payload: resultArray,
      });
    }, (error) => {
      // Handle the error
      console.error("Error fetching partner products:", error);
    });
};