import React, {useEffect, useMemo, useState} from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { debounce } from "lodash";
import axios from "axios";
import * as API from "../util/api";
import { ArrowRightOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Input, Button, message} from "antd";
import { ToastContainer, toast } from "react-toastify";
import { Mixpanel } from '../util/mixpanel';
import PublicNavBar from "../nav/publicNavBar";

import qs from 'query-string';
//firebase
import Firebase, { googleProvider } from "../util/firebase";
import { useDispatch } from "react-redux";

const Join = ({location, history}) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [referralCode, setReferralCode] = useState(null);
  const [activeRole, setActiveRole] = useState("");
  useEffect(() => {
    const getEmail = () => {
      const searchParams = qs.parse(location.search);
      const emailString = searchParams.email;
      const code = searchParams.referralCode;
      if(emailString){
        setEmail(emailString);
        Mixpanel.identify(emailString)
      }
      if(code){
        setReferralCode(code)
      }
    }
    getEmail();
    Mixpanel.track("[WEB] Step 1 Sign Up")
  },[location, setEmail]);

  const loginProfile = (profile, id) => ({
    type:"LOG_IN",
    profile,
    id
  });

  const updateProfile = (profile) => ({
    type:"UPDATE_PROFILE",
    payload:profile
  });

  const setVerifyFrom = () => ({
    type:"SET_VERIFY_FROM",
    payload: {
      target:"home",
      target_matchId:null
    }
  });

  const finishSignUp = async(values) => {
    const { email, firstName, password } = values;
    setLoading(true);
    let refer = "";
    if(values.refer){
      refer = values.refer;
    }
    try {
      const url = API.SIGNUP;
      const requestBody = {
        firstName,
        email,
        password,
        refer,
      };
      const response = await axios.post(url, requestBody);
      if (response.status === 200) {
        const info = response.data.userInfo;
        toast.success(
          "You have successfully sign up, a verification link has been sent to your email address. Please verify your email before log in!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          }
        );
        Mixpanel.track("[WEB] Step 3 Create Account")
        setUserInfo({...info, method:'email'});
        setStep(3);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("System Error, please try again or contact support", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const checkUser = async (user, uid) => {
    try {
      const userRef = Firebase.firestore().collection("users").doc(uid);
      const doc = await userRef.get();
    if(!doc.exists){
      const name = user.displayName? user.displayName.split(" ")[0]:"";
      const email = user.email;
      const requestBody = {
        uid,
        name,
        email
      }
      const endpoint = API.CREATE_USER_DOC;
      await axios.post(endpoint, requestBody);
      setTimeout(() => {
        processUserWithGoogle(uid)
      },500)
    }else {
      processUserWithGoogle(uid)
    }
    }catch(err){
      console.log(err);
      message.error("Failed to Sign In with Google, Please Try Again")
      setGoogleLoading(false);
    }
  }

  const dispatch = useDispatch();

  const storUserData = (userData, id) => {
    dispatch(loginProfile(userData, id));
    Firebase.firestore()
    .collection("users")
    .doc(id)
    .onSnapshot((doc) => {
      const data = doc.data();
      dispatch(updateProfile(data))
    });
  }

  const processUserWithGoogle = async (id) => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");
    const pageId = searchParams.get("id");

    const userRef = Firebase.firestore().collection("users").doc(id);
    
    try {
      const userData = (await userRef.get()).data();
      const {  firstTime, verify_status, savedEpicId } = userData;
      if(firstTime){
        const url = API.UPDATE_FIRSTTIME;
        const requestBody = {
          userId:id,
        };
        await axios.post(url, requestBody);
        storUserData(userData, id);
        setUserInfo({
          uid:id,
          name:userData.name,
          email:userData.email,
          method:'google'
        })
        setStep(3);
      }else {
        storUserData(userData, id);
      if(page === 'multiplier'){
        history.push("/multiplier")
      } else if(page === 'joinClub'){
        history.push("/joinClub")
      }else if(page === "creatorQuest") {
        history.push(`/creatorQuestCustomer/${pageId}`)
      }else if(page === 'liveShopping'){
        history.push(`/liveShoppingGame/${pageId}`)
      }else if(page === 'liveShoppingEvent' ) {
        history.push(`/liveShoppingEvent/${pageId}`)
      }else {
        history.push("/");
      }
    }
    }catch(err){
      message.error("Failed to Sign In with Google, Please Try Again")
      console.log(err);
    }finally {
      setGoogleLoading(false);
    }
  }



  const signInWithGoogle = () => {
    Firebase.auth()
    .signInWithPopup(googleProvider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;
  
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      // IdP data available in result.additionalUserInfo.profile.
        // ...
      Mixpanel.track("[WEB]Sign Up With Google")
      if(user.uid) {
        checkUser(user, user.uid);
      }
    }).catch((error) => {
      message.error("Failed to Sign In with Google, Please Try Again")
      console.log(error, 'error');

      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
  
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
  }

  const saveUserRole = async (role) => {
    if(userInfo) {
      const {uid,method} = userInfo;
      try {
        const url = API.SAVE_USER_ROLE;
        const requestBody = {
          userId:uid,
          role
        }
        await axios.post(url, requestBody);
      }catch(err){
        console.log(err);
      }finally {
        if(method === 'google'){
          history.push("/")
        }else {
          setStep(4)
        }
      }
    }
  }

  
  return <Container>
    <ToastContainer />
    <PublicNavBar isDarkMode={true}/>
      <Header>
        <img
              src="/assets/aq_logo.png"
              style={{ height: "45px" }}
              alt="logo"
            />
        </Header>
    {
      step === 1 && <ContentContainer>
          <Step>1</Step>
          <StepIntro>
          STEP <b>1</b> OF <b>3</b>
          </StepIntro>
          <StepOneText style={{marginBottom:16, height:"auto"}}>
          Set Up Your Account
          </StepOneText>
         {/*  <StepOneIntro>
          As a member you Get One Coin for Every Two Kills in Fortnite. 
          </StepOneIntro>
          <StepOneIntro>
          Your balance is updated daily and you can cash out for $USD at anytime.
          </StepOneIntro> */}
          {
            googleLoading ? <GoogleContainer>
            Loading With Google...
          </GoogleContainer>:<img
              src="/assets/google_login.svg"
              alt="google"
              style={{ width:345, cursor:'pointer', marginTop:16 }}
              onClick={() => {
                if(!googleLoading){
                  setGoogleLoading(true);
                  signInWithGoogle()
                }
              }}
            />
          }
          <Divider>
        or
      </Divider>
          <NextButton style={{
            width:345,
            height:54,
            font:"normal normal bold 18px/54px SF PRO, serif",
            marginTop:16
          }}onClick={() => {
            Mixpanel.track("[WEB] Step 2 Sign Up")
            setStep(2);
          }}>
            Sign Up With Email
          </NextButton>
      </ContentContainer>
    }
    {
      step === 2 && <ContentContainer>
      <StepIntro style={{textAlign:'left'}}>
        STEP <b>2</b> OF <b>3</b>
        </StepIntro>
        <StepOneText style={{textAlign:'left', marginBottom:12}}>
        Create a password to start your membership
        </StepOneText>
        <StepTwoText>
        Just a few more steps and you’re done!
        </StepTwoText>
        <Form
            style={{ margin: "0 auto"}}
            name="nest-messages"
            onFinish={finishSignUp}
          >
            <Label>Username</Label>
            <Form.Item
              name="firstName"
              wrapperCol={24}
              rules={[{ required: true, message: "username is required" }]}
            >
              <StyledInput
                style={{
                  height: 32,
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: 7,
                  border: "1px solid #000000",
                }}
                placeholder="username"
              />
            </Form.Item>
            <Label>Email</Label>
            <Form.Item
              name="email"
              wrapperCol={24}
              rules={[{ required: true, message: "email is required" }]}
              initialValue={email? email:""}
            >
              <StyledInput
                style={{
                  height: 32,
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: 7,
                  border: "1px solid #000000",
                }}
                placeholder="email"
              />
            </Form.Item>
            <Label>Password</Label>
            <Form.Item
              name="password"
              wrapperCol={24}
              rules={[{ required: true, message: "password is required" }]}
            >
              <StyledInput
                style={{
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: 7,
                  border: "1px solid #000000",
                  boxSizing: "border-box",
                }}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Label>Confirm Password</Label>
            <Form.Item
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <StyledInput
                style={{
                  height: 32,
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: 7,
                  border: "1px solid #000000",
                }}
                type="password"
                placeholder="ConfirmPassword"
              />
            </Form.Item>
            <Label>Referral Code</Label>
            <Form.Item
              name="refer"
              wrapperCol={24}
              initialValue={referralCode}
              /* rules={[
                { required: true, message: "referral code is required" },
              ]} */
            >
              <StyledInput
                style={{
                  height: 32,
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: 7,
                  border: "1px solid #000000",
                }}
                type="text"
                placeholder="Referral Code"
              />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 8 }} >
            <Button
                type="primary"
                htmlType="submit"
                disabled={loading}
                style={{
                  width: 392,
                  height: 72,
                  marginRight:35,
                  border: "none",
                  borderRadius: 11,
                  font: "normal normal bold 30px SF PRO, serif",
                  color: "#FFFFFF",
                  background: loading
                    ? "#B7B7B7 0% 0% no-repeat padding-box"
                    : "#F72375 0% 0% no-repeat padding-box"
                }}
              >
                {loading? 'Loading...':'Next'}
                {!loading && <ArrowRightOutlined />} 
              </Button>
            </Form.Item>
            </Form>
    </ContentContainer>
    }
    {
      step ===3 && userInfo && <ContentContainer>
        <StepIntro style={{textAlign:'left', marginTop:48}}>
        STEP <b>2</b> OF <b>3</b>
        </StepIntro>
        <StepOneText style={{textAlign:'left', height:'auto', marginBottom:24}}>
         Select your experience
        </StepOneText>
        <RoleContainer onMouseEnter={() => {
          setActiveRole("influencer")
        }} 
        onMouseLeave={() => {
          setActiveRole("")
        }}
        onClick={() => {
          saveUserRole("influencer")
        }}
        >
          <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', }}>
            <Role style={{marginBottom:8}}>Influencer</Role>
            <RoleDesc>
            The Sultans of Buzz
            </RoleDesc>
          </div>
          {
            activeRole === 'influencer' && <RightOutlined style={{color:"#F72375", marginLeft:'auto'}}/>
          }
        </RoleContainer>
        <RoleContainer onMouseEnter={() => {
          setActiveRole("publisher")
        }} 
        onMouseLeave={() => {
          setActiveRole("")
        }}
        onClick={() => {
          saveUserRole("publisher")
        }}
        >
          <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
            <Role style={{marginBottom:8}}>Game Publisher</Role>
            <RoleDesc>
            The creator of worlds, the master of universes.
            </RoleDesc>
          </div>
          {
            activeRole === 'publisher' && <RightOutlined style={{color:"#F72375", marginLeft:'auto'}}/>
          }
        </RoleContainer>
        <RoleContainer onMouseEnter={() => {
          setActiveRole("player")
        }} 
        onMouseLeave={() => {
          setActiveRole("")
        }}
        onClick={() => {
          saveUserRole("player")
        }}
        >
         <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
            <Role style={{marginBottom:8}}>Player</Role>
            <RoleDesc>
            You know… the gamer kind ;)
            </RoleDesc>
          </div>
          {
            activeRole === 'player' && <RightOutlined style={{color:"#F72375", marginLeft:'auto'}}/>
          }
        </RoleContainer>
      </ContentContainer>
    }
    {
      step === 4 && userInfo && <ContentContainer>
      <StepIntro style={{width:453, textAlign:'left', marginTop:48}}>
        STEP <b>3</b> OF <b>3</b>
        </StepIntro>
        <StepOneText style={{width:453, textAlign:'left', height:'auto', marginBottom:24}}>
        Check your email & verify
        </StepOneText>
        <StepTwoText style={{width:453}}>
        After you verify your email you can login and start competing.
        </StepTwoText>
        <Bouns>
        Verify and get  <img
                src="../assets/ic_mytokens.png"
                alt=""
                style={{
                  height: "30px",
                  marginRight: "8px",
                }}
              />
              50
        </Bouns>
        <NextButton onClick={() => {
             const redirectParams = qs.stringify({
              ...(qs.parse(location.search))
            });
            history.push(`/login?${redirectParams}`);
          }}
          style={{width:453, marginTop:64}}
          >
           Login <ArrowRightOutlined />
          </NextButton>
      </ContentContainer>
    }
  </Container>
}

const Container = styled.div`
  width: 100%;
  overflow-y: auto;
  background: #000000 0% 0% no-repeat padding-box;
  position: absolute;
  min-height: calc(100vh);

  overflow-x:hidden;
`;

const Header = styled.div`
background: #000000 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 7px #57575729;
height: 78px;
width:100%;
display:flex;
align-items:center;
justify-content:center;
margin-bottom:48px;

@media (max-width: 768px) {
  width: 95%; // Adjust for smaller screens
}
`;

const StyledInput = styled(Input)`
@media (max-width: 768px) {
  width: 95%; // Adjust for smaller screens
}
`;

const ContentContainer = styled.div`
display:flex;
width:100%;
flex-direction:column;
align-items:center;
overflow-y:auto;

.ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
  border: 0.5px solid #000000 !important;
}

@media (max-width: 768px) {
  width: 95%; // Adjust for smaller screens;
  margin:0 auto;
}


`;

const Step = styled.div`
width: 92px;
height: 92px;
/* UI Properties */
border: 3px solid #F72375;
border-radius:50%;
text-align:center;
font: normal normal bold 58px/92px SF Pro Rounded;
letter-spacing: 0px;
color: #F72375;
margin-bottom:16px;
`;


const StepIntro = styled.div`
text-align: center;
letter-spacing: 0px;
color: #FFFFFF;
text-transform: uppercase;
font: normal normal normal 24px SF PRO, serif;
margin-bottom:16px;

@media (max-width: 768px) {
  width: 95%; !important // Adjust for smaller screens
}
`;

const StepOneText = styled.div`
width: 376px;
height: 98px;
/* UI Properties */
text-align: center;
font: normal normal bold 38px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:48px;

@media (max-width: 768px) {
  width: 95%; // Adjust for smaller screens
}
`;

const StepOneIntro = styled.div`
font: normal normal 600 24px/34px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
width: 774px;
margin-bottom:12px;
text-align:center;
`;

const NextButton = styled.div`
margin-top:48px;
width: 392px;
height: 72px;
/* UI Properties */
background: #F72375 0% 0% no-repeat padding-box;
border-radius: 11px;
font: normal normal bold 36px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
text-align:center;
line-height:72px;
cursor:pointer;
`;

const Label = styled.span`
  text-align: left;
  font: normal normal 600 18px SF PRO, serif;
  letter-spacing: 0px;
  color: #727272;
  display: block;
  margin-bottom: 4px;
`;

const StepTwoText = styled.div`
font: normal normal 600 24px/34px SF PRO, serif;
letter-spacing: 0px;
color: #ACACAC;
text-align:left;
width:464px;
margin-bottom:12px;

@media (max-width: 768px) {
  width: 95%; // Adjust for smaller screens
}
`;


const Bouns = styled.div`
text-align: left;
font: normal normal bold 41px/57px SF PRO, serif;
letter-spacing: 0px;
color: #F72375;
width:453px;
margin-top:32px;

@media (max-width: 768px) {
  width: 95%; // Adjust for smaller screens
}
`;

const Divider = styled.div`
text-align: center;
font: normal normal 600 16px SF PRO, serif;
letter-spacing: 0px;
color: #D6D6D6;
width:345px;
margin-top:16px;
`;

const GoogleContainer = styled.div`
width: 345px;
height: 54px;
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 10px;
background: #B7B7B7 0% 0% no-repeat padding-box; 
cursor: not-allowed;
font:normal normal 600 24px/54px SF PRO, serif;
text-align:center;
color: #FFFFFF;
`;

const RoleContainer = styled.div`
width: 453px;
height: 80px;
/* UI Properties */
border-radius: 10px;
opacity: 1;
padding:24px;
display:flex;
align-items:center;
margin:16px auto;
&:hover,
&:focus {
  border: 1px solid #F72375;
  }
`;

const Role = styled.div`
text-align: left;
font: normal normal bold 24px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`

const RoleDesc = styled.div`
text-align: left;
font: normal normal normal 12px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

export default withRouter(Join);