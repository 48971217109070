import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Countdown from "./component/countdown";
import moment from "moment";
import { useSelector } from "react-redux";


const CurrentEvents = ({history}) => {

  const { items: events} = useSelector((state) => state.event);
  const customStyle = {
    color: '#FFF',
    textAlign: 'left',
    fontFamily: 'SF Pro Display',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight:'normal'
  };
  const sortedEvents = events.sort((a, b) => {
    if(moment(a.startTime).isBefore(moment(b.startTime))){
      return -1;
    }else {
      return 1;
    }
  })
  return <Container>
    {sortedEvents.map((item) => {
      return <div style={{width:351, cursor:'pointer', position:'relative'}} onClick={() => {
        history.push(`/liveShoppingEvent/${item.eventId}`)
      }}>
        {
          item.status === 1 && <img src="/assets/liveDot.svg" style={{
            width:16,
            height:16,
            top:16,
            right:16,
            position:'absolute'
          }} alt="liveDot"/>
        }
        <EventImage src={item?.backgroundImage}/>
        <Title>
          {item?.title}
        </Title>
        <div style={{display:'flex'}}>
          {
            item.status === 1 ? <div style={customStyle}>
             <p>Live</p>
              </div> :  <Countdown endDate={item.startTime} style={customStyle} />
          }
        <img src="/assets/event_countdown.svg" style={{
          height:14,
          marginLeft:12,
        }} alt="countdown"/>
        </div>
        <div style={{display:'flex', alignItems:'flex-end'}}>
          {
            item.status === 1 ? <Time>
              {moment(item.startAt).format('MMMM D, YYYY @ h:mma')}
            </Time> : <Time>
          {moment(item.startTime).format('MMMM D, YYYY @ h:mma')}
          </Time>
          }
          
          <div style={{marginLeft:'auto'}}>
            <Time style={{fontSize:12, marginBottom:8}}>
              More than
            </Time>
            <Discount>
              -25%
            </Discount>
          </div>
        </div>
      </div>
    })}
  </Container>
}
const Container = styled.div`
width:100%;
display:grid;
grid-template-columns: repeat(3, 351px);
grid-row-gap:32px;
grid-column-gap:80px;
margin:32px 0px 64px;

@media (max-width: 768px) {
  display: flex; // or 'block' depending on desired layout
  flex-direction: column; // only needed if using flex
  align-items:center;
}

`;

const EventImage = styled.img`
width:351px;
height:352px;
border-radius:10px;
`;

const Title = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin:12px 0px;
`;

const Time = styled.div`
color: #FFF;
text-align: right;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
`;

const Discount = styled.div`
color: #FFF;
text-align: center;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 29px;

width: 54px;
height: 29px;
flex-shrink: 0;

border-radius: 2px;
background: #F72375;
`;

export default withRouter(CurrentEvents);