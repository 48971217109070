import Modal from "react-modal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
// Import CSS files in your React component
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { debounce } from "lodash";

import Slider from 'react-slick';
import { message } from "antd";
import axios from "axios";
import * as API from "../../../util/api";


const DetailModal = ({game, modalOpen, closeModal}) => {
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);

  const voteCount = game?.upVoteCount? game.upVoteCount : 0;
  const voted = userId ? (game?.upVotes??[]).find(item => item.userId === userId) : false;
  console.log(voted, 'voted')

  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: "858px",
      height:'623px',
      background: "#2E2F30 0% 0% no-repeat padding-box",
      borderRadius: "2px",
      zIndex: "10000",
      border:"none",
      padding:'0px'
    },
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.25)' // Semi-transparent white background
    },
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const upVoteGame = async () => {
    try {
      const url = API.UP_VOTE_GAME;
    const res = await axios.post(url, {
      gameId:game.id,
      userId,
      userName: profile.first_name
    });
    if(res.status === 200){
      message.success("Upvote success")
    }else {
      message.error("Failed to upvote, please try again")
    }
    }catch {
      message.error("Failed to upvote, please try again")
    }
  }

  const unVoteGame = async () => {
    try {
      const url = API.UN_VOTE_GAME;
    const res = await axios.post(url, {
      gameId:game.id,
      userId
    });
    if(res.status === 200){
      message.success("Unvote success")
    }else {
      message.error("Failed to unvote, please try again")
    }
    }catch {
      message.error("Failed to unvote, please try again")
    }
  }
  const media = useMemo(() => {
    const arr = [];
    if(game && game.movies){
      arr.push({ type: 'video', src: game.movies[0], alt: 'Video 1' });
    }
    if(game && game.productImages){
      game.productImages.forEach(element => {
        arr.push({ type: 'image', src: element, alt: 'Image 1' })
      });
    }
    return arr;
  },[game])
  

  return <Modal
  isOpen={modalOpen}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
>
  <Header>
  This is the latest launch from {game?.publisher?? 'Steam'}
  </Header>
  <Content>
    <div style={{display:'flex', justifyContent:'space-between', marginBottom:'16px', alignItems:'flex-end'}}>
      <GameCardImg src={game?.bannerUrl} />
      <Position>
      <span class="baseline-sub"><sub>#</sub></span>{(game?.position??0) + 1}
      </Position>
    </div>
    <div style={{display:'flex', alignItems:'center'}}>
      <GameName>
        {game?.name}
      </GameName>
      <VisitButton>
          Visit
      </VisitButton>
      <VoteButton voted={voted} ableToVote={game.ableToVote} onClick={(event) => {
          event.stopPropagation();
          if(voted){
            if(game.ableToVote){
              debounce(() => unVoteGame(), 500)();
            }
          }else if(!game.ableToVote) {
            message.warn("Upvote is disabled")
          }else {
            if(loggedIn){
              debounce(() => upVoteGame(), 500)();
            }else {
              message.error("Please login first")
            }
          }
      }}>
      <TriangleUp voted={voted}/>
      <VoteCount>
         UPVOTE &nbsp; {voteCount}
        </VoteCount>
      </VoteButton>
    </div>
    <Price>
      {game?.regular_price}
    </Price>
    <Description>
      {game?.short_description}
    </Description>
    <Description style={{marginTop:'16px', display:'flex', alignItems:'center'}}>
      Launched In &nbsp;  {game?.genres?.map((genre) => (
           <Genre>
              • {genre}
            </Genre>
          ))}
    </Description>
    
  </Content>
  <div style={{width:'90%', margin:'24px auto'}}>
  <Slider {...settings}>
      {media.map((item, index) => (
        <div key={index}>
          {item.type === 'video' ? (
            <video controls style={{width:326, height:183}}>
              <source src={item.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={item.src} alt={item.alt} style={{width:326, height:183}}/>
          )}
        </div>
      ))}
    </Slider>
  </div>
</Modal>
}


const Header = styled.div`
width: 100%;
height: 40px;
flex-shrink: 0;
background: #3D0018;
text-align: center;
line-height:40px;

color: #FFF;
font-family: "SF Pro Display";
font-size: 16px;
font-style: normal;
font-weight: 400;

position:relative;
`;

const Content = styled.div`
margin:12px auto;
width:70%;
`;

const GameCardImg = styled.img`
width:149px;
margin-right:16px;
border-radius: 10px;
height:70px;
cursor:pointer;
`;

const Position = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

const GameName = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;


const VisitButton = styled.div`
width: 66px;
height: 53px;
flex-shrink: 0;
border-radius: 3px;
border: 1px solid #FFF;

color: #FFF;
font-family: "SF Pro Display";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 53px;
margin-left:auto;
margin-right:16px;
text-align:center;
cursor:pointer;
`;

const VoteButton = styled.div`
display: flex;
width: 187px;
height: 53px;
padding: 18px 20px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;

border-radius: 3px;
${p => p.voted && `background: #F72375;`}
border: 1px solid #F72375;
cursor:pointer;
`;

const TriangleUp = styled.div`
  width: 0;
  height: 0;
  border-left: 6.5px solid transparent;
  border-right: 6.5px solid transparent;
  ${p => p.voted ? `border-bottom: 8px solid #FFF;`:`border-bottom: 8px solid #F72375;`}
`;

const VoteCount = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top:2px;
`;

const Price = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin:16px auto;
`;

const Description = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;


const Genre = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-right:8px;
`;

export default DetailModal;