import React, {useMemo} from "react";
import styled from "styled-components";
import PrivateNavBar from "../nav/privateNavBar";
import Billing from "./billing";
import PersonalInformation from "./personalInformation";
import Sidebar from "./sidebar";
import Orders from "./orders";
import { Route, withRouter} from "react-router-dom";
import useIsMobile from "../util/isMobile";

const Account = ({location}) => {
  const isMobile = useIsMobile()

  const currentSelect = useMemo(() => {
    const pathName = location.pathname;
    const arr = pathName?.split("/")?? [];
    if(arr && arr[2]){
      return arr[2]
    }else {
      return null;
    }
  }, [location]);



  return <Container>
    <PrivateNavBar isDarkMode={true}/>
     <Sidebar currentSelect={currentSelect} isMobile={isMobile}/> 
    {/* <Sidebar currentSelect={currentSelect}/> */}
    <Content>
      {/* <Route path={"/myAccount/order"} component={SearchInfluencer} /> */}
      <Route path={"/myAccount/information"} component={() => {
        return <PersonalInformation isMobile={isMobile} />
      }} />
      <Route path={"/myAccount/transaction"} component={Billing} />
      <Route path={"/myAccount/orders"} component={Orders} />
    </Content>
  </Container>

}

const Container = styled.div`
  width: 100%;
  background:#1C1D20 0% 0% no-repeat padding-box;
  min-height:calc(100vh);
  padding-top:78px;
  overflow-y:auto;
  position:relative;
`;

const Content = styled.div`
width: 100%;
padding-left:289px;
padding-top:64px;

@media (max-width: 768px) {
  width: 95%; // Adjust for smaller screens;
  padding-left:0px;
  margin:0 auto;
}
`;

export default withRouter(Account)
