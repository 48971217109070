import { combineReducers } from "redux";
import user from "./user";
import partners from "./partners";
import event from "./event";

export default combineReducers({
  user,
  partners,
  event
});
