import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import {  Divider, Tooltip, } from "antd";

const YoutubeVideos = ({videos, isMobile}) => {
  const [main, setMain] = useState(null);

  useEffect(() => {
    if(videos.length > 0){
      setMain(videos[0])
    }
  }, [videos]);

  const topVideos = useMemo(() => {
    return videos.slice(0,5);
  }, [videos]);

  const additionalVideos = useMemo(() => {
    return topVideos.filter(item => item?.youtube_link !== main?.youtube_link)
  }, [topVideos, main]);

  const switchVideo = (link) => {
    const target = videos.find(item => item.youtube_link === link);
    if(target){
      setMain(target);
    }
  }

  if(isMobile){
    return <>
      <ReactPlayer
    url={main.youtube_link}
    width="100%"
    playing={true}
    controls={true}
  />
  <StreamTitle>
  {main?.video_name}
  </StreamTitle>
  <StreamerName>
  {main?.channel_name}
  </StreamerName>
  </>
  }else {

  return <Container style={{ display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
    <SubTitle style={{marginBottom:24}}>
      Youtube Videos
    </SubTitle>
    {
      main && <>
      <ReactPlayer
    url={main.youtube_link}
    width="944px"
    height="506px"
    playing={true}
    controls={true}
  />
  <StreamTitle>
    {main?.video_name}
  </StreamTitle>
  <StreamerName>
    {main?.channel_name}
  </StreamerName>
  <Divider />
      </>
    }
    <SubTitle style={{fontSize:16, marginBottom:24}}>
      More Videos
    </SubTitle>
    <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
    {
      additionalVideos.map((item) => {
        return <div key={item.id} style={{display:'flex', width:'224px', flexDirection:'column', alignItems:'flex-start'}}>
          <Tooltip title="Click to watch this streamer">
          <img src={item.thumbnail_image} alt="streamImage" onClick={() => {
            switchVideo(item.youtube_link);
          }}
          style={{cursor:'pointer',
          width:224,
          height:149
        }}
          />
          </Tooltip>
          <StreamTitle style={{fontSize:14}}>
            {item?.video_name}
          </StreamTitle>
          <StreamerName style={{fontSize:12}}>
            {item?.channel_name}
          </StreamerName>      
          </div>
      })
    }
    </div>
    </Container>
  }
}

const Container = styled.div`
.ant-divider-horizontal {
  border-top: 1px solid #F72375;
}
`;


const SubTitle = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 26px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
`;

const StreamTitle = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin:16px 0px;

display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit the text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Display "..." for overflow text */
  white-space: normal; /* Ensure text wraps within the two lines */
`;

const StreamerName = styled.div`
color: #F72375;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
margin-bottom:12px;
`;


export default YoutubeVideos;