import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import ReactPlayer from "react-player";
import GameCard from "./component/gameCard";
import EventChat from "./component/eventChat";
import EventGameDetail from "./component/eventGameDetail";
import CurrentGameOrder from "./component/currentGameOrder";
import { Progress } from "antd";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import * as API from "../../util/api";
import { Mixpanel } from '../../util/mixpanel';
import OrderModal from "./component/orderModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {message} from 'antd';

const EventAfterStartMobile = ({history, event, gameDetails, leaveEvent}) => {

  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);

  const [modalOpen, setModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if(loading){
      setLoading(false)
    }
  }, [])

  const currentGame = useMemo(() => {
    if(event && event.currentPromoteGame){
      const gameId = event.currentPromoteGame;
      const gameDescription = gameDetails.find(item => item.gameId === gameId);
      const gameDetail = event.games.find(item => item.gameId === gameId);
      return {
        ...gameDetail,
        gameDescription
      }
    }
    return null;
  }, [event, gameDetails]);

  const remainingPercent = useMemo(() => {
    if(currentGame){
      const total = currentGame.copies??0;
      const remaining = currentGame?.codes?.length??0;
      return (remaining/total).toFixed(2) * 100;
    }
  }, [currentGame])

  const myOrders = useMemo(() => {
    if(loggedIn && userId){
      const orders = event?.orders??[];
      return orders.filter(item => item.userId ===  userId);
    }
    return [];
  }, [event, userId, loggedIn]);

  const ownCurrentGame = useMemo(() => {
    if(event.currentPromoteGame && myOrders.length > 0){
      if(myOrders.some(item => item.gameId === event.currentPromoteGame)){
        return true;
      }
    }
    return false;
  }, [event, myOrders])


  const processToBuyGame = async () => {
    if(loggedIn && profile ){
      setLoading(true)
      Mixpanel.track("[WEB] Prcoess to Buy Liveshopping Game", {
        game:currentGame?.gameDescription?.name,
        gameId:currentGame?.gameId,
        publisher:currentGame?.gameDescription?.publisher
      })
      await loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY_GAME);
      const { email } = profile;
      const gameId = currentGame?.gameId;
      const requestBody = {
        gameId,
        userId,
        email,
        eventId:event.eventId
      }
      const url = API.LIVESHOPPINGEVENT_GAME_CHECKOUT;
      axios
      .post(url, requestBody)
      .then((res) => {
        const { data } = res;
        const { url } = data;
        window.location.replace(url);
        /* console.log(url) */
      })
      .catch((e) => {
        setLoading(false);
        message.error("Failed to buy, please try again")
        console.log("Error tokens", e)});
    }else {
      redirectToLogin()
    }
  }

  const redirectToLogin = () => {
    history.push(`/login?page=liveShoppingEvent&id=${event.eventId}`)
  }

  const getGameImageFromOrder = (order) => {
    const {gameId} = order;
    const game = gameDetails.find(item => item.gameId === gameId);
    return game?.bannerUrl;
  }


  return <Container>
    <div style={{display:'flex', justifyContent:'space-between', width:'100%', flexDirection:'column'}}>
    <Title>
      <img src="/assets/back.svg" alt="back" onClick={() => {
            leaveEvent()
            history.push(`/liveShoppingEvents`)
          }} style={{cursor:'pointer', marginRight:12}}/> 
      {event?.title}
    </Title>
    {
      currentGame && loggedIn &&  <ViewButton onClick={() => {
        setModalOpen(true)
      }}>
        My Orders
      </ViewButton>
    }
    </div>
    <div style={{display:'flex', width:'100%', marginTop:24, flexDirection:'column' }}>
    <ReactPlayer
    url={event?.webStreamUrl??"https://www.twitch.tv/arcadequestofficial"}
    width="100%"
    playing={true}
    controls={true}
  />
    <div style={{width:'100%'}}>
      {
        !currentGame ? <></> : <div>
          <div style={{marginBottom:12}}>
          <CurrentPrice style={{marginBottom:8}}>
              {currentGame.gameName}
            </CurrentPrice>
           <div style={{display:'flex', alignItems:'flex-end'}}>
            <OriginalPrice>
              ${currentGame?.gameDescription?.original_price}
            </OriginalPrice>
            <CurrentPrice>
             ${currentGame?.price}
            </CurrentPrice>
            </div>
            <Progress
                percent={remainingPercent}
                showInfo={false}
                strokeColor="#F72375"
                strokeWidth={3}
              />
          <div style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
            <Text>
              KEYS REMAINING
              </Text>
              <Text>
              {currentGame?.codes?.length}
              </Text>
          </div>
          {
            ownCurrentGame ? <OwnButton>
              Owned
            </OwnButton> : <BuyButton onClick={() => {
              if(!loading){
                processToBuyGame()
              }
          }}
          disabled={loading}
          >
            {
              !loggedIn && `Log In To Buy`
            }
            {
              loggedIn && !loading && `Buy Now`
            }
            {
              loading && `Redirecting to Payment ...`
            }
          </BuyButton>
          }
            </div>
            <EventChat chatId={event?.chatId} size="small"/>
          </div>
      }
    </div>
    </div>
    {
      !currentGame &&  <div style={{display:'flex', width:"100%", flexDirection:'column', marginTop:32}}>
          <GamesContainer>
      {gameDetails.map(item => {
        return <GameCard game={item} />
      })}
    </GamesContainer>
      <div style={{width:'100%'}}>
        <SubTitle style={{marginBottom:16}}>
          Your Orders
        </SubTitle>
        {
          myOrders.map((item) => {
            return <div style={{width:"100%", marginBottom:16}}>
              <div style={{display:'flex'}}>
                <img src={getGameImageFromOrder(item)} alt="gameBackground" style={{
                  width:141,
                  height:70
                }}/>
                <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{
                    color: '#FFF',
                    fontFamily: 'SF Pro Display',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'uppercase',
                  }}>
                    {item.gameName}
                  </div>
                    <div style={{
                      color: '#FFF',
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      textTransform: 'uppercase',
                      marginTop:'auto'
                    }}>
                      Congratulations on your new game!
                    </div>
                </div>
              </div> 
              <CopyToClipboard
                  text={item.code}
                  onCopy={() =>
                    message.success("Code is copied")
                  }
                >
                   <KeyBox>
                <img src="/assets/steamGame.svg" alt="steam" style={{
                  height:14,
                  width:14,
                  marginRight:8
                }} />
                {item.code}
                <img src="/assets/copy.svg" alt="back" style={{marginLeft:12, cursor:'pointer'}}/>
              </KeyBox>
                  </CopyToClipboard>       
             
              </div>
          })
        }
      </div>
      </div>
    }
    {
      currentGame && !ownCurrentGame && <EventGameDetail game={currentGame.gameDescription}/>
    }
    {
      currentGame && ownCurrentGame && <CurrentGameOrder game={currentGame} myOrders={myOrders} />
    }
    <OrderModal 
    orders={myOrders}
    gameDetails={gameDetails}
    modalOpen={modalOpen}
    closeModal={() => {
      setModalOpen(false);
    }}
    />
  </Container>
}

const Container = styled.div`
width:1320px;
margin:36px auto;

@media (max-width: 768px) {
  width: 95%; // Adjust for smaller screens;
  overflow-x:hidden;
}
`;

const Title = styled.div`
color: #FAFAFA;
font-family: SF Pro Display;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const GamesContainer = styled.div`
display:flex;
flex-direction:column;
`;

const OriginalPrice = styled.div`
color: rgba(255, 255, 255, 0.73);
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-decoration:line-through;
margin-right:12px;
`;

const CurrentPrice = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;


const Text = styled.div`
color: #C2C2C3;
font-family: SF Pro Display;
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

const BuyButton = styled.div`
margin:12px 0px;
display: flex;
width: 344px;
padding: 16px 10px 16px 16px;
justify-content: center;
align-items: center;
gap: 16px;
border-radius: 8px;
background: #F72375;

color: #FAFAFA;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
cursor:pointer;

${(p) =>
  p.disabled
    && `background: #B7B7B7 0% 0% no-repeat padding-box;
    cursor:not-allowed;
    `}
`;

const OwnButton = styled.div`
display: flex;
width: 344px;
padding: 16px 10px 16px 16px;
justify-content: center;
align-items: center;
gap: 16px;

border-radius: 8px;
border:1px solid #F72375;

color: #F72375;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top:12px;
`;

const ViewButton = styled.div`
display: flex;
width: 344px;
padding: 12px 10px 12px 16px;
justify-content: center;
align-items: center;
gap: 16px;

border-radius: 8px;
border:1px solid #F72375;

color: #F72375;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;

cursor:pointer;
`;


const SubTitle = styled.div`
color: #FAFAFA;
font-family: SF Pro Display;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const KeyBox = styled.div`
width: 344px;
height: 44px;
flex-shrink: 0;
border-radius: 10px;
background: #000;
margin-top:16px;
display:flex;
align-items:center;
justify-content:center;

color: #FFF;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;

export default withRouter(EventAfterStartMobile)