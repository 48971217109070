import useIsMobile from "../../util/isMobile";
import GameDetailPageMobile from "./gameDetailPageMobile";
import GameDetailPageWeb from "./gameDetailPageWeb";

const GameDetail = ({match}) => {
  const isMobile = useIsMobile();
  const gameId =  match.params.id;

  return <>
  {
    isMobile ? <GameDetailPageMobile gameId={gameId}/> : <GameDetailPageWeb gameId={gameId} />
  }
  </>
}

export default GameDetail;