import React, {useCallback, useState} from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import styled from "styled-components";
import PublicNavBar from "../nav/publicNavBar";
import { ToastContainer, toast } from "react-toastify";
import ForgetPasswordModal from "./component/forgetPassWordModal";
import { useDispatch } from "react-redux";
import { RightOutlined } from '@ant-design/icons';

//firebase
import Firebase, { googleProvider } from "../util/firebase";

import * as API from "../util/api";
import axios from "axios";
import qs from 'query-string';
import { Mixpanel } from "../util/mixpanel";


const Login = ({location, history}) => {
  const [modelOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);

  const [step, setStep] = useState(1);
  const [activeRole, setActiveRole] = useState("");
  const [userInfo, setUserInfo] = useState(null);

  const loginProfile = (profile, id) => ({
    type:"LOG_IN",
    profile,
    id
  });

  const updateProfile = (profile) => ({
    type:"UPDATE_PROFILE",
    payload:profile
  });

  const setVerifyFrom = () => ({
    type:"SET_VERIFY_FROM",
    payload: {
      target:"home",
      target_matchId:null
    }
  });

  const dispatch = useDispatch();


  const checkUser = async (user, uid) => {
    try {
      const userRef = Firebase.firestore().collection("users").doc(uid);
      const doc = await userRef.get();
    if(!doc.exists){
      const name = user.displayName? user.displayName.split(" ")[0]:"";
      const email = user.email;
      const requestBody = {
        uid,
        name,
        email
      }
      const endpoint = API.CREATE_USER_DOC;
      await axios.post(endpoint, requestBody);
      setTimeout(() => {
        processUserWithGoogle(uid)
      },500)
    }else {
      processUserWithGoogle(uid)
    }
    Mixpanel.track("[WEB] Google Login")
    }catch(err){
      console.log(err);
      message.error("Failed to Sign In with Google, Please Try Again")
      setGoogleLoading(false);
    } 
  }


  const signInWithGoogle = () => {
  Firebase.auth()
  .signInWithPopup(googleProvider)
  .then((result) => {
    /** @type {firebase.auth.OAuthCredential} */
    var credential = result.credential;

    // This gives you a Google Access Token. You can use it to access the Google API.
    var token = credential.accessToken;
    // The signed-in user info.
    var user = result.user;
    // IdP data available in result.additionalUserInfo.profile.
      // ...
    if(user.uid) {
      checkUser(user, user.uid);
    }
  }).catch((error) => {
    message.error("Failed to Sign In with Google, Please Try Again")
    setGoogleLoading(false);
    console.log(error, 'error')
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;

    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;
    // ...
  });
  }


  const login =  async (values) => {
    setLoading(true);
    const { email, password } = values;

    try {
      const data = await Firebase.auth().signInWithEmailAndPassword(email, password);
      if(data.user.emailVerified){
        processUser(data.user.uid);
        Mixpanel.track("[WEB] Login")
      }else {
        data.user
        .sendEmailVerification()
        .then(function () {
          console.log('sent')
          toast.info("You haven't verify your email, verifaction link is sent to your email box");
          setLoading(false);
        })
        .catch(function (error) {});
      }
    }catch(err){
      console.log(err)
      setLoading(false);
      if (err.code === "auth/user-not-found") {
        toast.error("You don't have an account. Please sign up first!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      } else if (err.code === "auth/wrong-password") {
        toast.error("Email and Password are not match", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      } else {
        toast.error("Error! Please contact us!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    }
  }

  const storUserData = (userData, id) => {
    dispatch(loginProfile(userData, id));
    Firebase.firestore()
    .collection("users")
    .doc(id)
    .onSnapshot((doc) => {
      const data = doc.data();
      dispatch(updateProfile(data))
    });
  }

  const processUserWithGoogle = async (id) => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");
    const pageId = searchParams.get("id");

    const userRef = Firebase.firestore().collection("users").doc(id);
    
    try {
      const userData = (await userRef.get()).data();
      const {  firstTime, verify_status, savedEpicId } = userData;
      if(firstTime){
        const url = API.UPDATE_FIRSTTIME;
        const requestBody = {
          userId:id,
        };
        await axios.post(url, requestBody);
        storUserData(userData, id);
        // user have not select role
        if(!userData.role){
          setUserInfo({
            uid:id,
          })
          setStep(2);
        }else {
          if(userData.role === 'influencer' || userData.role === 'publisher') {
            history.push("/")
          }
        }
      }else if(userData.role === 'influencer' || userData.role === 'publisher') {
        storUserData(userData, id);
        history.push("/")
      }else {
        storUserData(userData, id);
      if(page === 'multiplier'){
        history.push("/multiplier")
      } else if(page === 'joinClub'){
        history.push("/joinClub")
      }else if(page === "creatorQuest") {
        history.push(`/creatorQuestCustomer/${pageId}`)
      }else if(page === "proLeague") {
        history.push(`/proLeagueCustomer/${pageId}`)
      }else if(page === 'liveShopping'){
        history.push(`/liveShoppingGame/${pageId}`)
      }else if(page === 'liveShoppingEvent' ) {
        history.push(`/liveShoppingEvent/${pageId}`)
      }else {
        history.push("/");
      }
    }
    }catch(err){
      message.error("Failed to Sign In with Google, Please Try Again")
      console.log(err);
    }finally {
      setGoogleLoading(false);
    }
  }

  const processUser = async (id) => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");
    const pageId = searchParams.get("id");

    const userRef = Firebase.firestore().collection("users").doc(id);
    
    try {
      const userData = (await userRef.get()).data();
      const {  firstTime, verify_status, savedEpicId } = userData;
     /*  const passwordUrl = API.SAVE_PASSWORD;
      const passwordRequestBody = {
        userId: id,
        password,
      };
      await axios.post(passwordUrl, passwordRequestBody); */
      if(firstTime){
        const url = API.UPDATE_FIRSTTIME;
        const requestBody = {
          userId:id,
        };
        await axios.post(url, requestBody);
        storUserData(userData, id);
        // user have not select the role
        if(!userData.role){
          setUserInfo({
            uid:id,
          })
          setStep(2);
        }else {
          if(userData.role === 'influencer' || userData.role === 'publisher') {
            history.push("/")
          }
        }
      }else if(userData.role === 'influencer' || userData.role === 'publisher') {
        storUserData(userData, id);
        history.push("/")
      }else {
        storUserData(userData, id);
      if(page === 'multiplier'){
        history.push("/multiplier")
      } else if(page === 'joinClub'){
        history.push("/joinClub")
      }else if(page === "creatorQuest") {
        history.push(`/creatorQuestCustomer/${pageId}`)
      }else if(page === "proLeague") {
        history.push(`/proLeagueCustomer/${pageId}`)
      }else if(page === 'liveShopping'){
        history.push(`/liveShoppingGame/${pageId}`)
      }else if(page === 'liveShoppingEvent' ) {
        history.push(`/liveShoppingEvent/${pageId}`)
      }else {
        history.push("/");
      }
    }
  }catch(err){
      console.log(err);
    }finally {
      setLoading(false);
    }
  }

  const toSignUp = () => {
    const redirectParams = qs.stringify({
      ...(qs.parse(location.search))
  });

    history.push(`/signup?${redirectParams}`);
  }

  const saveUserRole = async (role) => {
    if(userInfo) {
      const {uid} = userInfo;
      try {
        const url = API.SAVE_USER_ROLE;
        const requestBody = {
          userId:uid,
          role
        }
        await axios.post(url, requestBody);
      }catch(err){
        console.log(err);
      }finally {
        if(role === 'player'){
          history.push("/start")
        }else {
          history.push("/")
        }
      }
    }
  }

  return <Container>
  <ToastContainer />
  <PublicNavBar isDarkMode={true}/>
  {
    step === 1 && <>
     <LeftContainer>
    <SignUpForm>
      <Title>Login to ArcadeQuest</Title>
      {
        googleLoading ? <GoogleContainer>
          Loading With Google...
        </GoogleContainer>: <img
              src="/assets/google_login.svg"
              alt="google"
              style={{ width:345, cursor:'pointer' }}
              onClick={() => {
                if(!googleLoading){
                  setGoogleLoading(true)
                  signInWithGoogle()
                }
              }}
            />
      }
      <Divider>
        or
      </Divider>
      <Form
        style={{ margin: "0 auto" }}
        name="nest-messages"
        onFinish={login}
      >
        <Label>Email</Label>
        <Form.Item
          name="email"
          wrapperCol={24}
          rules={[{ required: true, message: "email is required" }]}
        >
          <Input
            style={{
              width: 345,
              marginTop: 8,
              height: 32,
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              borderRadius: 7,
              border: "1px solid #000000",
              fontSize:16
            }}
            placeholder="Email"
          />
        </Form.Item>
        <Label>Password</Label>
        <Form.Item
          name="password"
          wrapperCol={24}
          rules={[{ required: true, message: "password is required" }]}
        >
          <Input
            style={{
              width: 345,
              height: 32,
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              borderRadius: 7,
              border: "1px solid #000000",
              fontSize:16
            }}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Forgot>
          <ForgetText onClick={() => {
            setModalOpen(true);
          }}>
            Forgot Password
          </ForgetText>
        </Forgot>
        <Form.Item wrapperCol={{ span: 8 }}>
          <Button
            htmlType="submit"
            disabled={loading}
            style={{
              width: 144,
              height: 49,
              border: "none",
              borderRadius: 11,
              font: "normal normal bold 18px/17px SF PRO, serif",
              color: "#FFFFFF",
              background: loading
                ? "#B7B7B7 0% 0% no-repeat padding-box"
                : "#F72375 0% 0% no-repeat padding-box",
              /* boxShadow: loading
                ? "0px 16px 24px #B7B7B7"
                : "0px 12px 24px #F7237569;", */
            }}
          >
            {loading ? "Loading..." : "Login"}
          </Button>
        </Form.Item>
      </Form>
      <Bottom>
        <Instruction>Don’t have an account?</Instruction>
        <SignUpText onClick={toSignUp}>Sign Up</SignUpText>
      </Bottom>
    </SignUpForm>
  </LeftContainer>
  <RightContainer url="../assets/login_background.jpeg">
    <BigTitle>Get 50 Coins</BigTitle>
    <BigTitle>When You Join</BigTitle>
    <Small>Compete with friends and win prizes!</Small>
  </RightContainer>
    </>
  }
  {
    step ===2 && <ContentContainer style={{marginTop:118}}>
    <StepIntro style={{width:453, textAlign:'left', marginTop:48}}>
        STEP <b>2</b> OF <b>2</b>
      </StepIntro>
    <StepOneText style={{width:453, textAlign:'left', height:'auto', marginBottom:24}}>
     Select your experience
    </StepOneText>
    <RoleContainer onMouseEnter={() => {
      setActiveRole("influencer")
    }} 
    onMouseLeave={() => {
      setActiveRole("")
    }}
    onClick={() => {
      saveUserRole("influencer")
    }}
    >
      <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', }}>
        <Role style={{marginBottom:8}}>Influencer</Role>
        <RoleDesc>
        The Sultans of Buzz
        </RoleDesc>
      </div>
      {
        activeRole === 'influencer' && <RightOutlined style={{color:"#F72375", marginLeft:'auto'}}/>
      }
    </RoleContainer>
    <RoleContainer onMouseEnter={() => {
      setActiveRole("publisher")
    }} 
    onMouseLeave={() => {
      setActiveRole("")
    }}
    onClick={() => {
      saveUserRole("publisher")
    }}
    >
      <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
        <Role style={{marginBottom:8}}>Game Publisher</Role>
        <RoleDesc>
        The creator of worlds, the master of universes.
        </RoleDesc>
      </div>
      {
        activeRole === 'publisher' && <RightOutlined style={{color:"#F72375", marginLeft:'auto'}}/>
      }
    </RoleContainer>
    <RoleContainer onMouseEnter={() => {
      setActiveRole("player")
    }} 
    onMouseLeave={() => {
      setActiveRole("")
    }}
    onClick={() => {
      saveUserRole("player")
    }}
    >
     <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
        <Role style={{marginBottom:8}}>Player</Role>
        <RoleDesc>
        You know… the gamer kind ;)
        </RoleDesc>
      </div>
      {
        activeRole === 'player' && <RightOutlined style={{color:"#F72375", marginLeft:'auto'}}/>
      }
    </RoleContainer>
  </ContentContainer>
  }
  <ForgetPasswordModal
    open={modelOpen}
    closeModal={() => {
      setModalOpen(false);
    }}
  />
</Container>
}

const ForgetText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  width: auto;
`;

const Forgot = styled.div`
  width: 345px;
  margin: 8px auto;
  font: normal normal normal 16px/18px SF PRO, serif;
  letter-spacing: 0px;
  color: #b7b7b7;
  text-align: right;
`;

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  background: #000000 0% 0% no-repeat padding-box;
  position: absolute;
  min-height: calc(100vh);
`;

const SignUpForm = styled.div`
  height: auto;
  text-align: center;
  width: 345px;
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none !important;
    border: 0.5px solid #000000 !important;
  }
  .ant-form-item {
    margin-bottom:16px;
  }
`;

const Title = styled.div`
  text-align: left;
  font: normal normal 600 24px/53px SF PRO, serif;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 16px;
  color: #FFFFFF;
`;

const Label = styled.span`
  text-align: left;
  font: normal normal 600 18px SF PRO, serif;
  letter-spacing: 0px;
  color: #000000;
  width: 345px;
  display: block;
`;

const Bottom = styled.div`
  text-align: left;
`;

const Instruction = styled.span`
  text-align: left;
  font: normal normal normal 16px/18px SF PRO, serif;
  letter-spacing: 0px;
  color: #b7b7b7;
`;

const SignUpText = styled.span`
  text-decoration: underline;
  font: normal normal normal 16px/53px SF PRO, serif;
  letter-spacing: 0px;
  color: #f72375;
  margin-left: 16px;
  cursor: pointer;

`;

const LeftContainer = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000 0% 0% no-repeat padding-box;

  @media (max-width: 768px) {
    width: 95%; // Adjust for smaller screens;
    margin:0 auto;
  }
`;

const RightContainer = styled.div`
background-image: url(${(p) => p.url});
background-size: cover;
  height: calc(100vh);
  width: 40%;
  padding-top: 32%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    display:none;
  }
`;

const BigTitle = styled.span`
  display: block;
  font: normal normal bold 38px/53px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
`;

const Small = styled.span`
  display: block;
  font: normal normal 600 24px/34px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  width: 270px;
  margin-top: 32px;
`;


const Divider = styled.div`
text-align: center;
font: normal normal 600 16px SF PRO, serif;
letter-spacing: 0px;
color: #D6D6D6;
width:345px;
margin-top:16px;
`;

const GoogleContainer = styled.div`
width: 345px;
height: 54px;
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 10px;
background: #B7B7B7 0% 0% no-repeat padding-box; 
cursor: not-allowed;
font:normal normal 600 24px/54px SF PRO, serif;
text-align:center;
color: #FFFFFF;
`;

const RoleContainer = styled.div`
width: 453px;
height: 80px;
/* UI Properties */
border-radius: 10px;
opacity: 1;
padding:24px;
display:flex;
align-items:center;
margin:16px auto;
&:hover,
&:focus {
  border: 1px solid #F72375;
  }
`;

const Role = styled.div`
text-align: left;
font: normal normal bold 24px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`

const RoleDesc = styled.div`
text-align: left;
font: normal normal normal 12px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
`;

const ContentContainer = styled.div`
display:flex;
width:100%;
flex-direction:column;
align-items:center;
`;

const StepOneText = styled.div`
width: 376px;
height: 98px;
/* UI Properties */
text-align: center;
font: normal normal bold 38px SF PRO, serif;
letter-spacing: 0px;
color: #FFFFFF;
margin-bottom:48px;
`;

const StepIntro = styled.div`
text-align: center;
letter-spacing: 0px;
color: #FFFFFF;
text-transform: uppercase;
font: normal normal normal 24px SF PRO, serif;
margin-bottom:16px;
`;


export default withRouter(Login);