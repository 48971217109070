import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import * as API from "../util/api";
import Loading from "../util/loading";
import ReactPlayer from "react-player";
import GameBot from "./gameBot";
import { Mixpanel } from '../util/mixpanel';

const GamePromote = ({match}) => {
  const [game, setGame] = useState(null);
 
  useEffect(() => {
    const appId = match?.params?.id;
    const fetchGame = async() => {
      const endpoint = API.GET_STEAM_GAME_RAW;
      try {
        const res = await axios.get(endpoint, {
          params:{
            appId
          }
        });
        if(res.status === 200){
          const detail = res.data.game;
          setGame(detail);
        }
      }catch(err){
        console.log(err);
      }
    }
    fetchGame()
  }, [match]);

  useEffect(() => {
    if (game) {
      Mixpanel.track("BotPromote Page View", { name: game.name }); // Track the page view with the game name property
    }
  }, [game]);

  
  return <Container>
    <Logo src="/assets/arcadequest_logo_white.png" />
    {
      game ? <>
      <Title>
      Welcome to your personalized chatbot for {game?.name}
      </Title>
      <Content>
        <div>
          <Detail>
            <TopContainer style={{display:'flex'}}>
              <GameImage src={game.bannerUrl} alt="gameBanner"/>
              <div>
                <GameName>
                  {game.name}
                </GameName>
                <div style={{display:'flex',gap:'0.5em', flexWrap:'wrap', marginTop:'1rem'}}>
                {game?.genres.map(tag => <Tag>
                  {tag}
                </Tag>)}
                </div>
              </div>
            </TopContainer>
            <Desc>
              {game.short_description}
            </Desc>
          <PlayContainer>
          <ReactPlayer
          url={"https://youtu.be/MothgO7-Y1o"}
          playing={false}
          width="454px"
          controls
        />
          </PlayContainer>
          </Detail>
        </div>
        <Right>
          <SubTitle>
          This bot is only trained on a small dataset for demonstration purposes and may provide incorrect information. Reach out to our team to learn how to get your bot trained on a larger dataset. This demo is limited to 20 messages.
          </SubTitle>
          <GameBot game={game} />
        </Right>
      </Content>
      </> : <Loading width="80%"/>
    }
  </Container>
}
const Container = styled.div`
  background:#1C1D20 0% 0% no-repeat padding-box;
  min-height:calc(100vh);
  border-top: 1px solid transparent;
  display:flex;
  flex-direction:column;
  align-items: center; /* Centers horizontally */

  @media (max-width: 768px) {
    overflow-x:hidden;
    width: 100%;
  }
  
`;

const Logo = styled.img`
width:116px;
height:36px;
margin-top:3rem;
`;

const Content = styled.div`
display:flex;
gap:4rem;

@media (max-width: 768px) {
 flex-direction:column;
 gap:1rem;
}
`;

const TopContainer = styled.div`
display:flex;
width:454px;

@media (max-width: 768px) {
  width:100%;
  flex-direction:column;
  gap:1rem;
 }
`;

const Detail = styled.div`
display:flex;
flex-direction:column;

@media (max-width: 768px) {
  width:95%;
  margin:0 auto;
}
`;

const Title = styled.div`
color: #FFF;
text-align: center;
font-family: "SF Pro Display";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin:24px 0px 36px;
`;


const Right = styled.div`
width:616px;

@media (max-width: 768px) {
  width: 95%;
  border-top:1px solid white;
  padding-top:1rem;
  margin:0 auto;
}
`;


const GameImage = styled.img`
width:192px;
margin-right:1rem;

@media (max-width: 768px) {
  width: 100%;
}
`;

const GameName = styled.div`
color: #FFF;
font-family: "SF Pro Display";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;

const Tag = styled.div`
display: inline-flex;
padding: 3px 18px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 100px;
background: #000;

color: #FFF;
font-family: "SF Pro Display";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;

const Desc = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
max-width:454px;
margin:1rem auto;

@media (max-width: 768px) {
  max-width:95%;
}
`;

const PlayContainer = styled.div`
@media (max-width: 768px) {
  display:none;
}
`;

const SubTitle = styled.div`
color: #FFF;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom:2.5rem;

@media (max-width: 768px) {
  margin-bottom:1rem;
}
`;

export default GamePromote;